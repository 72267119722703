import * as pdfjsLib from "pdfjs-dist";

pdfjsLib.GlobalWorkerOptions.workerSrc =
  window.location.origin + "/pdf.worker.min.mjs";

  export const parsePdfToText = async (
    file: File,
    action?: (text: string) => void
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = async () => {
        try {
          const typedArray = new Uint8Array(reader.result as ArrayBuffer);
          const pdf = await pdfjsLib.getDocument({ data: typedArray }).promise;
          let text = "";
  
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const content = await page.getTextContent();
            const strings = content.items.map((item: any) => item.str);
            text += strings.join(" ") + "\n\n";
          }
  
          if (action) action(text);
          resolve(text);
        } catch (error) {
          reject(error);
        }
      };
  
      reader.onerror = (err) => {
        reject(err);
      };
  
      reader.readAsArrayBuffer(file);
    });
  };
  