import {
  Box,
  Button,
  ContentLayout,
  Header,
  KeyValuePairs,
  Link,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { Invoice } from "../models/Invoice";
import { useEffect, useState } from "react";
import { fetchItem, fetchItemsWithOrFilters } from "../api/generic";
import { useAuthContext } from "../contexts/AuthContext";
import { prefixCollection } from "../utils/prefix";
import { fetchUrlFromPath } from "../api/storage";
import GraphView from "../components/modules/GraphView";
import { Batch } from "../models/Batch";
import { GraphLink } from "../models/GraphLink";
import GridLinkInvoiceModal from "../components/modules/GridLinkInvoiceModal";

const InvoicePage = () => {
  const { id } = useParams();
  const { tenant } = useAuthContext();
  const navigate = useNavigate();

  const [invoice, setInvoice] = useState<Invoice>();
  const [url, setUrl] = useState<string>();
  const [showModal, setShowModal] = useState(false);

  const [graphData, setGraphData] = useState<GraphLink[]>([]);
  const [batchData, setBatchData] = useState<Batch[]>([]);

  const fetchGraphData = async () => {
    const response = await fetchItemsWithOrFilters<GraphLink>(
      prefixCollection("graphLinks", tenant),
      {
        or: [
          { field: "from", operator: "==", value: id },
          { field: "to", operator: "==", value: id },
        ],
      }
    );
    setGraphData(response);

    if (response.length > 0) {
      const batchesFromGraph = await fetchItemsWithOrFilters<Batch>(
        prefixCollection("batches", tenant),
        {
          or: response.flatMap((x) => {
            return [
              { field: "id", operator: "==", value: x.from },
              { field: "id", operator: "==", value: x.to },
            ];
          }),
        }
      );

      setBatchData(batchesFromGraph);
    }
  };

  const fetchInvoice = async () => {
    if (!id) return;
    const response = await fetchItem<Invoice>(
      prefixCollection("invoices", tenant),
      id
    );

    if (response) {
      setUrl(await fetchUrlFromPath(response.fileUrl));
      setInvoice(response);
    }
  };

  useEffect(() => {
    fetchInvoice();
    fetchGraphData();
  }, [id]);

  return (
    <>
      <ContentLayout
        disableOverlap
        header={
          <Header
            variant="h1"
            description={
              "Hint: All ingredients in a batch must be linked to an invoice. For audit tracking, you can link multiple invoices to a batch."
            }
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Button
                  variant="normal"
                  iconName={"angle-left"}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <Button
                  variant="normal"
                  iconName={"grid-view"}
                  onClick={() => setShowModal(true)}
                >
                  Link to batches
                </Button>
              </SpaceBetween>
            }
          >
            {invoice?.supplierName} - {invoice?.invoiceNumber ?? ""}
          </Header>
        }
      >
        {invoice ? (
          <SpaceBetween size="l" direction="vertical">
            <KeyValuePairs
              columns={3}
              items={[
                { label: "Supplier", value: invoice.supplierName },
                { label: "Invoice number", value: invoice.invoiceNumber },
                {
                  label: "Invoice date",
                  value: new Date(
                    invoice.invoiceDate.toString()
                  ).toLocaleDateString(),
                },
                {
                  label: "Total Amount Excl. VAT",
                  value: `${invoice.totalAmountExclVat} ${invoice.currency}`,
                },
                {
                  label: "VAT Amount",
                  value: `${invoice.vatAmount} ${invoice.currency}`,
                },
                {
                  label: "Total Amount Incl. VAT",
                  value: (
                    <b>{`${invoice.totalAmountInclVat} ${invoice.currency}`}</b>
                  ),
                },
                { label: "Notes", value: invoice.notes },
                {
                  label: "File",
                  value: (
                    <Link external={true} href={url}>
                      {invoice.fileUrl}
                    </Link>
                  ),
                },
              ]}
            />

            {invoice?.items && (
              <SpaceBetween size="m" direction="vertical">
                <Box variant="h3">Items</Box>
                <KeyValuePairs
                  columns={4}
                  items={invoice.items.flatMap((item, i) => [
                    {
                      label: i === 0 ? "Description" : "",
                      value: item.description,
                    },
                    {
                      label: i === 0 ? "Per Unit" : "",
                      value: `${item.unitPrice} ${invoice.currency}`,
                    },
                    {
                      label: i === 0 ? "Quantity" : "",
                      value: `${item.quantity} ${item.unit}`,
                    },
                    {
                      label: i === 0 ? "Total" : "",
                      value: `${item.total} ${invoice.currency}`,
                    },
                  ])}
                />
              </SpaceBetween>
            )}

            {id && graphData?.length > 1 && (
              <GraphView
                id={id}
                type={"invoice"}
                graphData={graphData}
                labels={[
                  ...batchData.map((batch) => {
                    return {
                      label: `${batch.batchId} ${batch.name}`,
                      value: batch.id,
                      type: "batch",
                    };
                  }),
                  {
                    label: `${invoice?.supplierName} - ${invoice?.invoiceNumber ?? ""}`,
                    value: id,
                    type: "invoice",
                  },
                ]}
              />
            )}
          </SpaceBetween>
        ) : (
          <Spinner size="big" />
        )}
      </ContentLayout>
      <GridLinkInvoiceModal
        showModal={showModal}
        graphData={graphData}
        batchData={batchData}
        setShowModal={setShowModal}
        onSave={() => {
          fetchGraphData();
        }}
      />
    </>
  );
};

export default InvoicePage;
