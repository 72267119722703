import { SqMoney } from "../api/squarespace";
import Data from "../data/bottles";
import { Batch } from "../models/Batch";

export const costTotal = (batch: Batch): number => {
  if (!batch.recipe || !batch.recipe.ingredients || batch.recipe.ingredients.length === 0) {
    return 0;
  }

  const { ingredients } = batch.recipe;

  // Safely calculate the recipe yield
  const recipeYield = batch.recipe.batchSize ?? batch.expectedYield ?? batch.volume ?? 1;
  const batchYield = batch.expectedYield ?? batch.volume ?? 1;

  // Prevent division by zero
  if (!recipeYield || !batchYield) return 0;

  const scalingFactor = batchYield / recipeYield;

  const totalCost = ingredients.reduce((total, ingredient) => {
    const { item, quantity } = ingredient;
    if (!item || typeof item.price !== "number" || typeof quantity !== "number") return total;
    const scaledQuantity = quantity * scalingFactor;
    return total + scaledQuantity * item.price;
  }, 0);

  return totalCost;
};

export const costPerUnit = (batch: Batch): number => {
  if (!batch || !batch.quantity) return 0;

  const batchYield = batch.expectedYield ?? batch.volume ?? 1;
  
  // Avoid division by zero
  if (batchYield <= 0) return 0;

  return costTotal(batch) / batchYield;
};

export const costPerBottle = (batch: Batch, bottleId: string): number => {
  if (!batch.recipe) return 0;

  const bottle = Data.bottles.find((b) => b.id === bottleId);
  if (!bottle || typeof bottle.volume !== "number" || typeof bottle.price !== "number") return 0;

  const unitCost = costPerUnit(batch);
  return unitCost * bottle.volume + bottle.price;
};


export const asPrice = (pricing: SqMoney): string => {
  if (!pricing || !pricing.currency || !pricing.value) return "";
  return `${pricing.currency} ${pricing.value}`;
}
