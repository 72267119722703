import React from "react";

import { Box, ColumnLayout } from "@cloudscape-design/components";

interface KeyValuePairProps {
  label: string;
  value: string | number;
}

const KeyValuePair: React.FC<KeyValuePairProps> = ({ label, value }) => {
  return (
    <ColumnLayout columns={2} variant="text-grid">
      <Box fontWeight="bold">{label}</Box>
      <Box>{value}</Box>
    </ColumnLayout>
  );
};

export default KeyValuePair;
