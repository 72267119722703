import {
  Box,
  ColumnLayout,
  ExpandableSection,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FC } from "react";
import { useLocalStorage } from "react-use";
import { ExciseInfo } from "../models/Batch";
import { toDate } from "../utils/timestamp";

interface IExciseDutyProps {
  exciseDuty: ExciseInfo;
}

const ExciseDuty: FC<IExciseDutyProps> = ({ exciseDuty }) => {
  const [exciseDutyExpanded, setExciseDutyExpanded] = useLocalStorage<boolean>(
    "exciseDutyExpanded",
    false
  );

  return (
    <div style={{ marginTop: 10 }}>
      <ExpandableSection
        variant="container"
        headerText="Excise duty information"
        expanded={exciseDutyExpanded}
        onChange={({ detail }) => setExciseDutyExpanded(detail.expanded)}
      >
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="m" direction="horizontal">
            <Box variant="awsui-key-label">Amount paid</Box>
            <Box>
              €{" "}
              {exciseDuty
                ? (exciseDuty?.exciseDutyAmount ?? 0).toFixed(2)
                : "0.00"}
            </Box>
          </SpaceBetween>
          <SpaceBetween size="m" direction="horizontal">
            <Box variant="awsui-key-label">Date paid</Box>
            <Box>
              {exciseDuty?.exciseDutyPaid
                ? toDate(exciseDuty?.exciseDutyPaid)?.toLocaleDateString()
                : "Not paid"}
            </Box>
          </SpaceBetween>
          <SpaceBetween size="m" direction="horizontal">
            <Box variant="awsui-key-label">Payment reference ID</Box>
            <Box>{exciseDuty?.exciseDutyPaymentReferenceId ?? "N/A"}</Box>
          </SpaceBetween>
        </ColumnLayout>
      </ExpandableSection>
    </div>
  );
};

export default ExciseDuty;
