import { useEffect, useState } from "react";

import {
  Box,
  Button,
  ContentLayout,
  ExpandableSection,
  Header,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";

import {
  retrieveAllProducts,
  SqImage,
  SqInventoryItem,
  SqPricing,
  SqProduct,
  SqProductImages,
  SqStock,
} from "../api/squarespace";
import ErrorBar from "../components/ErrorBar";
import Loader from "../components/Loader";
import UpdateQuantityModal from "../components/UpdateQuantityModel";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import { Settings } from "../models/Settings";
import { prefixCollection } from "../utils/prefix";
import Image from "../components/Image";
import KeyValuePair from "../components/KeyValuePair";
import { asPrice } from "../utils/cost";

interface ProductVariantViewModel {
  id: string;
  name: string;
  type: "PHYSICAL" | "DIGITAL";
  url: string;
  sku: string;
  variantId: string;
  description: string;
  tags?: string[];
  images?: SqProductImages[];
  vImage?: SqImage;
  pricing: SqPricing;
  stock: SqStock;
}

const initalSettings: Settings = { id: "", integrations: [] };

const Stocks = () => {
  const { tenant } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [openClose, setOpenClose] = useState<string>("");
  const [imageShown, setImageShown] = useState<number>(0);
  const [products, setProducts] = useState<SqProduct[]>([]);
  const [viewModel, setViewModel] = useState<ProductVariantViewModel[]>([]);
  const [selectedItem, setSelectedItem] = useState<SqInventoryItem>();
  const [settings, setSettings] = useState<Settings | null>(null);
  const [error, setError] = useState<Error>();

  useSubscribe<Settings>(
    prefixCollection("settings", tenant),
    (result) => setSettings(result.length > 0 ? result[0] : initalSettings),
    setError
  );

  const fetchProducts = async () => {
    setIsLoading(true);
    const ssKey = settings?.integrations.find(
      (i) => i.integrationName === "squarespace"
    );

    if (ssKey) {
      const result = await retrieveAllProducts(ssKey.apiKey);
      console.log("result", result);
      setProducts(result?.products ?? []);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (settings) {
      fetchProducts();
    }
  }, [settings]);

  useEffect(() => {
    if (products.length > 0) {
      // cross map flat map

      const vms = products.flatMap((product) => {
        return (
          product.variants?.map((variant) => {
            return {
              id: variant.id,
              name: `${product.name} - ${variant?.attributes?.["Size"] ?? ""}`,
              type: product.type,
              url: product.url,
              sku: variant.sku,
              variantId: variant.id,
              description: product.description ?? "",
              tags: product.tags,
              images: product.images,
              vImage: variant.image,
              pricing: variant.pricing,
              stock: variant.stock ?? { quantity: 0, unlimited: false },
            };
          }) ?? []
        );
      });

      setViewModel(vms ?? []);
    }
  }, [products]);

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          actions={
            <SpaceBetween size="m" direction="horizontal"></SpaceBetween>
          }
          variant="h1"
          description="Hint: The stock uses squarespace as the truth."
        >
          Stock
        </Header>
      }
    >
      <div style={{ marginTop: 10 }}>
        <ErrorBar error={error} setError={setError} />
        <Loader isLoading={isLoading} size="large">
          <SpaceBetween size="m" direction="vertical">
            {viewModel.length > 0 &&
              viewModel.map((vm) => (
                <ExpandableSection
                  key={vm.sku}
                  variant="container"
                  headerText={
                    <span
                      style={{
                        color:
                          (vm?.stock?.quantity ?? 0) < 1 ? "gray" : undefined,
                      }}
                    >{`${vm.name} (${vm?.stock?.quantity ?? 0})`}</span>
                  }
                  expanded={openClose === vm.sku}
                  onChange={({ detail }) =>
                    setOpenClose(detail.expanded ? vm.sku : "")
                  }
                >
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 24 }}
                  >
                    <Image
                      widthPart={3}
                      src={
                        (vm.images ?? [])[imageShown % (vm.images?.length ?? 1)]
                          ?.url
                      }
                      onClick={
                        (vm.images?.length ?? 1) > 1
                          ? () => setImageShown((i) => i + 1)
                          : undefined
                      }
                    />
                    <div style={{ flex: 1 }}>
                      <KeyValuePair label="SKU" value={vm.sku} />
                      <KeyValuePair
                        label="Price"
                        value={asPrice(vm.pricing.basePrice)}
                      />
                      <KeyValuePair
                        label="In stock"
                        value={
                          vm.stock.unlimited ? "Unlimited" : vm.stock.quantity
                        }
                      />
                      <KeyValuePair
                        label="Value"
                        value={asPrice({
                          currency: vm.pricing.basePrice.currency,
                          value: (
                            parseFloat(vm.pricing.basePrice.value) *
                            (vm.stock.quantity ?? 0)
                          ).toFixed(0),
                        })}
                      />
                      <div
                        dangerouslySetInnerHTML={{ __html: vm.description }}
                      />
                      <Button
                        onClick={() =>
                          setSelectedItem({
                            variantId: vm.id,
                            sku: vm.sku,
                            descriptor: vm.name,
                            isUnlimited: vm.stock.unlimited,
                            quantity: vm.stock.quantity ?? 0,
                          })
                        }
                      >
                        Update stock
                      </Button>
                    </div>
                  </div>
                </ExpandableSection>
              ))}
          </SpaceBetween>
        </Loader>
      </div>
      <UpdateQuantityModal
        visible={!!selectedItem}
        item={selectedItem}
        onClose={() => setSelectedItem(undefined)}
        onUpdate={fetchProducts}
      />
    </ContentLayout>
  );
};

export default Stocks;
