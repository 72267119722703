import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  onSnapshot,
  or,
  Query,
  query,
  QuerySnapshot,
  setDoc,
  updateDoc,
  where,
  WhereFilterOp,
} from "@firebase/firestore";

import { db } from "../config/firebase";

export type Filter<T> = {
  field: keyof T;
  operator: WhereFilterOp;
  value: T[keyof T] | string | number | boolean;
};

export type OrFilter<T> = {
  or: Filter<T>[];
};

export const createItem = async <T extends { id?: string }>(
  collectionName: string,
  item: Omit<T, "id">
): Promise<T> => {
  try {
    const docRef = await addDoc(collection(db, collectionName), {
      ...item,
    });

    return { ...item, id: docRef.id } as T;
  } catch (error) {
    console.error("Error creating item:", error);
    throw new Error("Failed to create item in collection: " + collectionName);
  }
};

export const createItemWithId = async <T extends Record<string, any>>(
  collectionName: string,
  id: string,
  item: T
): Promise<T & { id: string }> => {
  try {
    const docRef = doc(db, collectionName, id); // Create a document reference with the specified ID
    await setDoc(docRef, item); // Add the item data to the document

    return { ...item, id }; // Return the item data with the specified ID
  } catch (error) {
    console.error("Error creating item with ID:", error);
    throw new Error("Failed to create item in collection: " + collectionName);
  }
};

export const updateItem = async <T extends { id: string }>(
  collectionName: string,
  itemId: string,
  item: Partial<Omit<T, "id">>
): Promise<T> => {
  try {
    const itemDoc = doc(db, collectionName, itemId);
    await updateDoc(itemDoc, { ...item });
    return { ...(item as T), id: itemId };
  } catch (error) {
    console.error("Error updating item:", error);
    throw new Error("Failed to update item in collection: " + collectionName);
  }
};

export const createOrUpdateItem = async <T extends { id: string }>(
  collectionName: string,
  item: T
): Promise<void> => {
  try {
    const docRef = doc(db, collectionName, item.id); // Reference to the document
    await setDoc(docRef, item, { merge: true }); // Merge option ensures only provided fields are updated
  } catch (error) {
    console.error(`Error creating/updating item in ${collectionName}:`, error);
    throw new Error(`Failed to create/update item in ${collectionName}.`);
  }
};

export const deleteItem = async (
  collectionName: string,
  itemId: string
): Promise<void> => {
  try {
    const itemDoc = doc(collection(db, collectionName), itemId);
    await deleteDoc(itemDoc);
  } catch (error) {
    console.error("Error deleting item:", error);
    throw new Error("Failed to delete item from collection: " + collectionName);
  }
};

export const fetchItem = async <T extends { id?: string }>(
  collectionName: string,
  itemId: string
): Promise<T | null> => {
  try {
    const docRef = doc(db, collectionName, itemId); // Reference to the document
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id } as T; // Combine data with `id`
    } else {
      return null; // Document does not exist
    }
  } catch (error) {
    console.error(`Error fetching item with id ${itemId} from ${collectionName}:`, error);
    throw new Error("Failed to fetch item.");
  }
};

export const fetchItems = async <T extends { id?: string }>(
  collectionName: string
): Promise<T[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    const items: T[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        }) as T
    );

    return items;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw new Error("Failed to fetch items from collection: " + collectionName);
  }
};

export const fetchItemsByIds = async <T extends { id?: string }>(
  collectionName: string,
  ids: string[]
): Promise<T[]> => {
  try {
    if (ids.length === 0) return [];

    const uniqueIds = Array.from(new Set(ids));

    const docs = await Promise.all(
      uniqueIds.map(async (id) => {
        const ref = doc(db, collectionName, id);
        const snap = await getDoc(ref);
        return snap.exists() ? ({ ...snap.data(), id: snap.id } as T) : null;
      })
    );

    return docs.filter(Boolean) as T[];
  } catch (error: unknown) {
    const err = error instanceof Error ? error.message : String(error);
    console.error("Error fetching items by IDs:", err);
    throw new Error("Failed to fetch items by IDs from collection: " + collectionName);
  }
};



export const fetchItemsWithFilter = async <T extends { id?: string }>(
  collectionName: string,
  filters: Filter<T>[] = [] // Accept an array of filters
): Promise<T[]> => {
  try {
    // Start with the collection reference
    const collectionRef: CollectionReference = collection(db, collectionName);

    // Dynamically apply all filters to the query
    let q: Query = query(collectionRef); // Start as a query
    filters.forEach((filter) => {
      q = query(
        q,
        where(filter.field as string, filter.operator, filter.value)
      );
    });
    

    // Fetch documents
    const querySnapshot = await getDocs(q);

    // Map the documents to an array of items
    const items: T[] = querySnapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        }) as T
    );

    return items;
  } catch (error) {
    console.error("Error fetching items with filters:", error);
    throw new Error("Failed to fetch items with filters from collection: " + collectionName);
  }
};

export const fetchItemsWithOrFilters = async <T extends { id?: string }>(
  collectionName: string,
  orFilterGroup: OrFilter<T>
): Promise<T[]> => {
  try {
    const collectionRef = collection(db, collectionName) as CollectionReference<T>;

    const orQuery = or(
      ...orFilterGroup.or.map((f) =>
        where(f.field as string, f.operator, f.value)
      )
    );

    const q = query(collectionRef, orQuery);
    const snapshot = await getDocs(q);

    return snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  } catch (err) {
    console.error("Failed OR query", err);
    throw err;
  }
};


export const subscribeToChanges = <T extends { id?: string }>(
  collectionName: string,
  onChange: (items: T[]) => void,
  onError: (error: Error) => void,
  itemId?: string
): (() => void) => {
  const collectionRef = collection(db, collectionName);
  const unsubscribe = itemId
    ? onSnapshot(
        doc(collectionRef, itemId),
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const item = { ...docSnapshot.data(), id: docSnapshot.id } as T;
            onChange([item]);
          } else {
            onError(new Error("Document does not exist"));
          }
        },
        (error) => {
          console.error("Error subscribing to document changes:", error);
          onError(new Error("Failed to subscribe to document changes"));
        }
      )
    : onSnapshot(
        collectionRef,
        (querySnapshot: QuerySnapshot<DocumentData>) => {
          const items: T[] = querySnapshot.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              }) as T
          );
          onChange(items);
        },
        (error) => {
          console.error("Error subscribing to collection changes:", error);
          onError(new Error("Failed to subscribe to collection changes"));
        }
      );

  return unsubscribe;
};

export const subscribeToFilteredChanges = <T extends { id?: string }>(
  collectionName: string,
  filters: Filter<T>[],
  onChange: (items: T[]) => void,
  onError: (error: Error) => void
): (() => void) => {
  try {
    // Start with the collection reference
    const collectionRef: CollectionReference = collection(db, collectionName);

    // Dynamically apply all filters to the query
    let q: Query = query(collectionRef); // Start as a query
    filters.forEach((filter) => {
      q = query(
        q,
        where(filter.field as string, filter.operator, filter.value)
      );
    });

    // Subscribe to changes
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const items: T[] = querySnapshot.docs.map(
          (doc) =>
            ({
              ...doc.data(),
              id: doc.id,
            }) as T
        );
        onChange(items);
      },
      (error) => {
        console.error("Error subscribing to filtered changes:", error);
        onError(new Error("Failed to subscribe to filtered changes"));
      }
    );

    return unsubscribe; // Return the unsubscribe function
  } catch (error) {
    console.error("Error setting up filtered subscription:", error);
    onError(new Error("Failed to set up filtered subscription for collection: " + collectionName));
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}; // Return a no-op unsubscribe if an error occurs
  }
};

export const getCount = async (collectionName: string): Promise<number> => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  return querySnapshot.size;
};

export const fetchItemsDistinctBy = async <T extends { id?: string }>(
  collectionName: string,
  distinctField: keyof T
): Promise<T[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));

    // Create a map to track unique values for the distinct field
    const uniqueItemsMap = new Map<T[keyof T], T>();

    querySnapshot.docs.forEach((doc) => {
      const item = { ...doc.data(), id: doc.id } as T;
      const fieldValue = item[distinctField] as T[keyof T];
      if (!uniqueItemsMap.has(fieldValue)) {
        uniqueItemsMap.set(fieldValue, item);
      }
    });

    // Convert the map values to an array
    return Array.from(uniqueItemsMap.values());
  } catch (error) {
    console.error("Error fetching distinct items:", error);
    throw new Error("Failed to fetch distinct items from collection: " + collectionName);
  }
};

export const fetchItemsDistinctByWithFilter = async <T extends { id?: string }>(
  collectionName: string,
  distinctField: keyof T,
  filters: Filter<T>[] = []
): Promise<T[]> => {
  try {
    // Start with the collection reference
    const collectionRef: CollectionReference = collection(db, collectionName);

    // Dynamically apply all filters to the query
    let q: Query = query(collectionRef);
    filters.forEach((filter) => {
      q = query(
        q,
        where(
          filter.field as string,
          filter.operator,
          filter.value as T[keyof T]
        )
      );
    });

    // Fetch documents
    const querySnapshot = await getDocs(q);

    // Create a map to track unique values for the distinct field
    const uniqueItemsMap = new Map<T[keyof T], T>();

    querySnapshot.docs.forEach((doc) => {
      const item = { ...doc.data(), id: doc.id } as T;
      const fieldValue = item[distinctField];
      if (!uniqueItemsMap.has(fieldValue)) {
        uniqueItemsMap.set(fieldValue, item);
      }
    });

    // Convert the map values to an array
    return Array.from(uniqueItemsMap.values());
  } catch (error) {
    console.error("Error fetching distinct items with filters:", error);
    throw new Error("Failed to fetch distinct items with filters from collection: " + collectionName);
  }
};

export const deleteAllByFilter = async <T>(
  collectionName: string,
  filters: Filter<T>[] = []
): Promise<void> => {
  try {
    // Start with the collection reference
    const collectionRef: CollectionReference = collection(db, collectionName);

    // Dynamically apply all filters to the query
    let q: Query = query(collectionRef);
    filters.forEach((filter) => {
      q = query(
        q,
        where(filter.field as string, filter.operator, filter.value)
      );
    });

    // Fetch documents matching the query
    const querySnapshot = await getDocs(q);

    // Delete each document
    const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));

    await Promise.all(deletePromises);

    console.log(
      `Deleted ${querySnapshot.size} documents from ${collectionName}`
    );
  } catch (error) {
    console.error("Error deleting items with filters:", error);
    throw new Error("Failed to delete items with filters from collection: " + collectionName);
  }
};

export const countByFilter = async <T>(
  collectionName: string,
  filters: Filter<T>[] = []
): Promise<number> => {
  try {
    // Start with the collection reference
    const collectionRef: CollectionReference = collection(db, collectionName);

    // Dynamically apply all filters to the query
    let q: Query = query(collectionRef);
    filters.forEach((filter) => {
      q = query(
        q,
        where(filter.field as string, filter.operator, filter.value)
      );
    });

    // Fetch documents matching the query
    const querySnapshot = await getDocs(q);

    // Return the count of matching documents
    return querySnapshot.size;
  } catch (error) {
    console.error("Error counting items with filters:", error);
    throw new Error("Failed to count items with filters from collection: " + collectionName);
  }
};
