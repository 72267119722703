import {
  Box,
  ColumnLayout,
  ExpandableSection,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Batch } from "../models/Batch";
import { FC } from "react";
import { useLocalStorage } from "react-use";
import { costTotal } from "../utils/cost";

interface IIngredientsProps {
  batch: Batch;
  batchSize: number;
}

export const Ingredients: FC<IIngredientsProps> = ({ batch, batchSize }) => {
  const [ingredientsExpanded, setIngredientsExpanded] =
    useLocalStorage<boolean>("ingredientsExpanded", false);

  return (
    <div style={{ marginTop: 10 }}>
      <ExpandableSection
        variant="container"
        headerText="Ingredients"
        expanded={ingredientsExpanded}
        onChange={({ detail }) => setIngredientsExpanded(detail.expanded)}
      >
        <ColumnLayout columns={2} variant="text-grid">
          {batch?.recipe?.ingredients.map((ingredient) => {
            return (
              <SpaceBetween
                key={ingredient.item.id + ingredient.quantity}
                size="m"
                direction="horizontal"
              >
                <Box variant="awsui-key-label">{ingredient.item.name}</Box>
                <Box>
                  {(ingredient.quantity * batchSize).toFixed(2)}{" "}
                  {ingredient.item.unit}{" "}
                  <small>
                    (€ {ingredient.item.price?.toFixed(2)}{" "}
                    <strong>
                      €{" "}
                      {(
                        (ingredient.item.price ?? 0) *
                        ingredient.quantity *
                        batchSize
                      ).toFixed(2)}
                    </strong>
                    )
                  </small>
                </Box>
              </SpaceBetween>
            );
          })}

          <SpaceBetween size="m" direction="horizontal">
            <Box variant="awsui-key-label">{"Total"}</Box>
            <Box>
              <strong style={{ textDecoration: "underline" }}>
                € {batch && costTotal(batch).toFixed(2)}
              </strong>
            </Box>
          </SpaceBetween>
        </ColumnLayout>
      </ExpandableSection>
    </div>
  );
};
