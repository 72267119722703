import {
  Box,
  Button,
  Container,
  ContentLayout,
  DatePicker,
  Header,
  Input,
  LiveRegion,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import LoadingBar from "@cloudscape-design/chat-components/loading-bar";
import { useEffect, useRef, useState } from "react";
import { parsePdfToText } from "../lib/invoiceParser";
import { Invoice } from "../models/Invoice";
import { useAuthContext } from "../contexts/AuthContext";
import { fetchUrlFromPath, uploadFileToStorage } from "../api/storage";
import { createItem, fetchItemsWithFilter, Filter } from "../api/generic";
import { prefixCollection } from "../utils/prefix";
import { toDate } from "../utils/timestamp";
import { useNavigate } from "react-router-dom";

export type InvoiceViewModel = {
  id: string;
  supplierName: string;
  invoiceNumber: string;
  invoiceDate: string; // formatted e.g. "Mar 25, 2025"
  uploadedAt: string; // formatted
  currency: string;
  totalAmount: string; // e.g., "€250.00"
  itemCount: number;
  status: "Parsed" | "Pending" | "Error";
  fileUrl?: string;
  items?: InvoiceViewModel[];
};

function getQuarter(date: Date): number {
  return Math.floor(date.getMonth() / 3) + 1;
}

const Invoices = () => {
  const { tenant, userObject } = useAuthContext();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [invoiceToAdd, setInvoiceToAdd] = useState<Invoice>();
  const [invoiceToAddFile, setInvoiceToAddFile] = useState<File>();

  // All invoice rows (parents + currently-expanded children)
  const [invoices, setInvoices] = useState<InvoiceViewModel[]>([]);

  // Tracks which parent invoice rows are expanded (by ID)
  const [expandedInvoiceIds, setExpandedInvoiceIds] = useState<Set<string>>(
    new Set()
  );

  // (Optional) Raw fetched data from Firestore before flattening
  // Only needed if you're toggling expansion dynamically at runtime
  const [rawInvoices, setRawInvoices] = useState<InvoiceViewModel[]>([]);

  const fetchInvoices = async () => {
    const filters: Filter<Invoice>[] = [
      {
        field: "uploadedBy",
        operator: "==",
        value: userObject?.uid,
      },
    ];

    const result = await fetchItemsWithFilter(
      prefixCollection("invoices", tenant),
      filters
    );

    const treeData: InvoiceViewModel[] = result.map((invoice) => ({
      id: invoice.id,
      supplierName: invoice.supplierName,
      invoiceNumber: invoice.invoiceNumber,
      invoiceDate: invoice.invoiceDate
        ? new Date(invoice.invoiceDate.toString()).toLocaleDateString()
        : "",
      uploadedAt: invoice.uploadedAt
        ? toDate(invoice.uploadedAt).toLocaleDateString()
        : "",
      currency: invoice.currency,
      totalAmount: `${invoice.totalAmountInclVat}`,
      itemCount: invoice.items.length,
      status: invoice.status === "parsed" ? "Parsed" : "Pending",
      fileUrl: invoice.fileUrl,
      items: invoice.items.map(
        (item) =>
          ({
            id: item.id.toString(), // Ensure all children have string ids
            supplierName: `${item.quantity} ${item.unit} ${item.description}`,
            totalAmount: `${item.total}`,
            currency: invoice.currency,
          }) as InvoiceViewModel
      ),
    }));

    setRawInvoices(treeData); // save raw nested data
    setInvoices(treeData); // flat version = just top-level for now
  };

  const handleOpenInvoice = async (invoice: InvoiceViewModel) => {
    if (!invoice.fileUrl) return;
    const url = await fetchUrlFromPath(invoice.fileUrl);
    window.open(url, "_blank");
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    let text = "";

    try {
      if (file.type === "application/pdf") {
        setProcessing(true);
        text = await parsePdfToText(file);
      } else {
        alert("Only PDF text extraction is supported in this demo.");
      }
    } catch (error) {
      console.error("Failed to extract text from file:", error);
    }

    // Exit early if no text extracted
    if (!text) {
      setProcessing(false);
      alert("No text extracted from the file.");
      return;
    }

    const response = await fetch(
      "https://parseinvoicefunction-ogbfqlrosa-ew.a.run.app",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text,
          filename: file.name,
          debug: true,
        }),
      }
    );

    const data = await response.json();
    setInvoiceToAdd({
      ...data,
      uploadedBy: userObject?.uid || "",
      uploadedAt: new Date(),
      status: "parsed",
      filename: file.name,
    });
    setInvoiceToAddFile(file);
    setProcessing(false);
    event.target.value = "";
    inputRef.current?.value && (inputRef.current.value = "");
  };

  const handleSaveInvoice = async () => {
    if (!invoiceToAdd || !invoiceToAddFile) return;

    const iDate = new Date(invoiceToAdd.invoiceDate.toString());
    const filePath = `${tenant}/invoices/${iDate.getFullYear()}-Q${getQuarter(iDate)}`;
    const fileName = `${invoiceToAdd.supplierName}-${invoiceToAdd.invoiceNumber}.${invoiceToAddFile.name.split(".").pop()}`;

    await uploadFileToStorage(
      invoiceToAddFile,
      fileName,
      filePath,
      () => {},
      () => alert("Error uploading file"),
      () => {
        const invoice: Invoice = {
          ...invoiceToAdd,
          fileUrl: filePath + "/" + fileName,
        };

        createItem(prefixCollection("invoices", tenant), invoice).then(() => {
          alert("Invoice saved successfully!");
          setInvoiceToAdd(undefined);
          setInvoiceToAddFile(undefined);
          fetchInvoices();
        });
      }
    );
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <>
      <input
        ref={inputRef}
        id="file-picker"
        type="file"
        accept=".pdf,.jpg,.jpeg,.png"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <ContentLayout
        disableOverlap
        header={
          <Header
            variant="h1"
            description={
              "Hint: All ingredients in a batch must be linked to an invoice. For audit tracking, you can link multiple invoices to a batch."
            }
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Button
                  disabled={processing}
                  variant="primary"
                  iconName={processing ? "status-pending" : "add-plus"}
                  onClick={() => inputRef.current?.click()}
                >
                  Add Invoice
                </Button>
              </SpaceBetween>
            }
          >
            Invoices
          </Header>
        }
      >
        <SpaceBetween size="m" direction="vertical">
          {processing && (
            <LiveRegion>
              <Box
                margin={{ bottom: "xs", left: "l" }}
                color="text-body-secondary"
              >
                Talking to geepetee
              </Box>
              <LoadingBar variant="gen-ai" />
            </LiveRegion>
          )}
          {invoiceToAdd && (
            <Container
              header={<Header>Invoice Details</Header>}
              footer={
                <SpaceBetween size="m" direction="horizontal">
                  <Button
                    variant="normal"
                    iconName="close"
                    onClick={() => setInvoiceToAdd(undefined)}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="primary"
                    iconName="check"
                    onClick={handleSaveInvoice}
                  >
                    Save
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size="m" direction="vertical">
                <Input
                  value={invoiceToAdd.supplierName}
                  onChange={({ detail }) =>
                    setInvoiceToAdd({
                      ...invoiceToAdd,
                      supplierName: detail.value,
                    })
                  }
                  placeholder="Supplier name"
                />

                <Input
                  value={invoiceToAdd.invoiceNumber}
                  onChange={({ detail }) =>
                    setInvoiceToAdd({
                      ...invoiceToAdd,
                      invoiceNumber: detail.value,
                    })
                  }
                  placeholder="Invoice number"
                />

                <DatePicker
                  value={invoiceToAdd.invoiceDate.toString()}
                  onChange={({ detail }) =>
                    setInvoiceToAdd({
                      ...invoiceToAdd,
                      invoiceDate: new Date(detail.value),
                    })
                  }
                />

                <Input
                  value={invoiceToAdd.currency}
                  onChange={({ detail }) =>
                    setInvoiceToAdd({ ...invoiceToAdd, currency: detail.value })
                  }
                  placeholder="Currency (e.g. EUR)"
                />

                <Input
                  value={invoiceToAdd.totalAmountInclVat.toString()}
                  type="number"
                  onChange={({ detail }) =>
                    setInvoiceToAdd({
                      ...invoiceToAdd,
                      totalAmountInclVat: parseFloat(detail.value) || 0,
                    })
                  }
                  placeholder="Total incl. VAT"
                />

                <Input
                  value={invoiceToAdd.vatAmount.toString()}
                  type="number"
                  onChange={({ detail }) =>
                    setInvoiceToAdd({
                      ...invoiceToAdd,
                      vatAmount: parseFloat(detail.value) || 0,
                    })
                  }
                  placeholder="VAT amount"
                />

                <Input
                  value={invoiceToAdd.totalAmountExclVat.toString()}
                  type="number"
                  onChange={({ detail }) =>
                    setInvoiceToAdd({
                      ...invoiceToAdd,
                      totalAmountExclVat: parseFloat(detail.value) || 0,
                    })
                  }
                  placeholder="Total excl. VAT"
                />

                <Header variant="h3">Items</Header>
                <SpaceBetween size="s" direction="vertical">
                  {invoiceToAdd.items.map((item, index) => (
                    <div key={item.id} style={{ marginBottom: 4 }}>
                      <div style={{ width: "100%", marginBottom: 2 }}>
                        <Input
                          value={item.description}
                          onChange={({ detail }) => {
                            const updatedItems = [...invoiceToAdd.items];
                            updatedItems[index] = {
                              ...item,
                              description: detail.value,
                            };
                            setInvoiceToAdd({
                              ...invoiceToAdd,
                              items: updatedItems,
                            });
                          }}
                          placeholder="Description"
                        />
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <Input
                          value={item.quantity.toString()}
                          type="number"
                          onChange={({ detail }) => {
                            const updatedItems = [...invoiceToAdd.items];
                            updatedItems[index] = {
                              ...item,
                              quantity: parseFloat(detail.value) || 0,
                            };
                            setInvoiceToAdd({
                              ...invoiceToAdd,
                              items: updatedItems,
                            });
                          }}
                          placeholder="Quantity"
                        />
                        <Input
                          value={item.unit}
                          onChange={({ detail }) => {
                            const updatedItems = [...invoiceToAdd.items];
                            updatedItems[index] = {
                              ...item,
                              unit: detail.value,
                            };
                            setInvoiceToAdd({
                              ...invoiceToAdd,
                              items: updatedItems,
                            });
                          }}
                          placeholder="Unit"
                        />
                        <Input
                          value={item.unitPrice.toString()}
                          type="number"
                          onChange={({ detail }) => {
                            const updatedItems = [...invoiceToAdd.items];
                            updatedItems[index] = {
                              ...item,
                              unitPrice: parseFloat(detail.value) || 0,
                            };
                            setInvoiceToAdd({
                              ...invoiceToAdd,
                              items: updatedItems,
                            });
                          }}
                          placeholder="Unit price"
                        />
                        <Input
                          value={item.total.toString()}
                          type="number"
                          onChange={({ detail }) => {
                            const updatedItems = [...invoiceToAdd.items];
                            updatedItems[index] = {
                              ...item,
                              total: parseFloat(detail.value) || 0,
                            };
                            setInvoiceToAdd({
                              ...invoiceToAdd,
                              items: updatedItems,
                            });
                          }}
                          placeholder="Total"
                        />
                      </div>
                    </div>
                  ))}
                </SpaceBetween>
              </SpaceBetween>
            </Container>
          )}

          {invoices && (
            <Table
              trackBy="id"
              items={invoices}
              columnDefinitions={[
                {
                  header: "Supplier",
                  id: "supplierName",
                  cell: (item) => item.supplierName,
                },
                {
                  header: "Invoice",
                  id: "invoiceNumber",
                  cell: (item) => item.invoiceNumber,
                },
                {
                  header: "Invoice Date",
                  id: "invoiceDate",
                  cell: (item) => item.invoiceDate,
                },
                {
                  header: "Uploaded",
                  id: "uploadedAt",
                  cell: (item) => item.uploadedAt,
                },

                {
                  header: "Total",
                  id: "totalAmount",
                  cell: (item) =>
                    `${item.currency} ${parseFloat(item.totalAmount).toFixed(2)}`,
                },
                {
                  header: "Items",
                  id: "itemCount",
                  cell: (item) => item.itemCount,
                },
                {
                  header: "Status",
                  id: "status",
                  cell: (item) => item.status,
                },
                {
                  header: "Actions",
                  id: "actions",
                  cell: (item) =>
                    item.fileUrl && (
                      <SpaceBetween size="s" direction="horizontal">
                        <Button
                          variant="inline-icon"
                          iconName="external"
                          onClick={() => handleOpenInvoice(item)}
                        />
                        <Button
                          variant="inline-icon"
                          iconName="caret-right-filled"
                          onClick={() => navigate(`/invoices/${item.id}`)}
                        />
                      </SpaceBetween>
                    ),
                },
              ]}
              expandableRows={{
                isItemExpandable: (item) => !!item.items?.length,
                getItemChildren: (item) =>
                  item.items?.map((child) => ({
                    ...child,
                    id: `${item.id}-${child.id}`,
                  })) ?? [],
                expandedItems: invoices.filter(
                  (i) => i.id.includes("-") // child IDs include hyphen
                ),
                onExpandableItemToggle: ({ detail }) => {
                  setExpandedInvoiceIds((prev) => {
                    const next = new Set(prev);
                    const id = detail.item.id;

                    if (next.has(id)) {
                      next.delete(id);
                      // Collapse: remove children
                      setInvoices((prevInvoices) =>
                        prevInvoices.filter((i) => !i.id.startsWith(`${id}-`))
                      );
                    } else {
                      next.add(id);
                      // Expand: find children from rawInvoices
                      const parent = rawInvoices.find((r) => r.id === id);
                      const children =
                        parent?.items?.map((child) => ({
                          ...child,
                          id: `${parent.id}-${child.id}`,
                          items: undefined,
                        })) ?? [];

                      setInvoices((prev) => {
                        const insertIndex = prev.findIndex((i) => i.id === id);
                        const next = [...prev];
                        next.splice(insertIndex + 1, 0, ...children);
                        return next;
                      });
                    }

                    return next;
                  });
                },
              }}
            />
          )}
        </SpaceBetween>
      </ContentLayout>
    </>
  );
};

export default Invoices;
