import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Badge,
  Box,
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  ContentLayout,
  DatePicker,
  ExpandableSection,
  FormField,
  Header,
  Icon,
  Input,
  Modal,
  Select,
  Slider,
  SpaceBetween,
} from "@cloudscape-design/components";
import { doc, Timestamp, updateDoc } from "@firebase/firestore";

import { createBatch, fetchBatch, updateBatch } from "../api/batches";
import {
  deleteItem,
  fetchItemsByIds,
  fetchItemsWithOrFilters,
  OrFilter,
} from "../api/generic";
import { fetchRecipe } from "../api/recipes";
import ErrorBar from "../components/ErrorBar";
import GravitySample from "../components/GravitySampleModal";
import HydroPi from "../components/modules/HydroPi";
import HydroPiConfigModal from "../components/modules/HydroPiConfig";
import UpdateQuantityModal from "../components/UpdateQuantityModel";
import { db } from "../config/firebase";
import { useAuthContext } from "../contexts/AuthContext";
import useSubscribe from "../hooks/useSubscribe";
import { Batch } from "../models/Batch";
import { Recipe } from "../models/Recipe";
import { GravitySample as GS, TasteSample } from "../models/Sampling";
import { Settings } from "../models/Settings";
import { costPerBottle, costPerUnit, costTotal } from "../utils/cost";
import { prefixCollection } from "../utils/prefix";
import { sortGravitySamples, sortName } from "../utils/sort";
import { addDaysBySteps, toDate } from "../utils/timestamp";
import TasteSampleModal from "../components/TasteSampleModal";
import { v4 } from "uuid";
import TasteVisualization from "../components/TasteRadarChart";
import ExciseDutyModal from "../components/ExciseDutyModal";
import { Ingredients } from "../modules/Ingredients";
import ExciseDuty from "../modules/ExciseDuty";
import Steps from "../modules/Steps";
import Taste from "../modules/Taste";
import Gravity from "../modules/Gravity";
import Cost from "../modules/Cost";
import CurrentStep from "../modules/CurrentStep";
import GridLinkBatchModal from "../components/modules/GridLinkBatchModal";
import { GraphLink } from "../models/GraphLink";
import { Invoice } from "../models/Invoice";
import { distinctBy } from "../utils/array";
import GraphView from "../components/modules/GraphView";
import { useLocalStorage } from "react-use";

export const renderStars = (score: number) => {
  const stars = [];
  const starCount = Math.round((score / 100) * 2) / 2; // Round to nearest 0.5
  const fullStars = Math.floor(starCount); // Number of full stars
  const halfStar = starCount % 1 !== 0; // Check if a half star is needed
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Remaining empty stars

  // Add full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(<Icon key={`full-${i}`} name="star-filled" />);
  }

  // Add half star if applicable
  if (halfStar) {
    stars.push(<Icon key="half" name="star-half" />);
  }

  // Add empty stars
  for (let i = 0; i < emptyStars; i++) {
    stars.push(<Icon key={`empty-${i}`} name="star" />);
  }

  return stars;
};

export const initalBatch: Batch = {
  id: "",
  name: "",
  recipeId: "",
  startDate: new Date(),
  status: "planned",
  quantity: 0,
  volume: 0,
  yield: 0,
  expectedYield: 0,
  currentStepIndex: 0,
  stepStatus: "not-started",
};

const BatchPage = () => {
  const { tenant } = useAuthContext();
  const { id, modal } = useParams();
  const navigate = useNavigate();

  const [batch, setBatch] = useState<Batch | null>(null);
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [gravitySamples, setGravitySamples] = useState<GS[]>([]);
  const [notes, setNotes] = useState("");
  const [score, setScore] = useState(-1);

  const [editMode, setEditMode] = useState<boolean>(id === "new");

  const [quantity, setQuantity] = useState("");
  const [volume, setVolume] = useState("");
  const [yieldAmount, setYieldAmount] = useState("");
  const [expectedYield, setExpectedYield] = useState("");

  const [showHydroPiModal, setShowHydroPiModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [editStep, setEditStep] = useState(-1);

  const [finishModal, setFinishModal] = useState(false);
  const [error, setError] = useState<Error>();
  const [settings, setSettings] = useState<Settings>();

  const [showTasteModal, setShowTasteModal] = useState(false);
  const [showExciseDutyModal, setShowExciseDutyModal] = useState(false);
  const [showGridLinkModel, setShowGridLinkModel] = useState(false);

  const [graphData, setGraphData] = useState<GraphLink[]>([]);
  const [invoiceData, setInvoiceData] = useState<Invoice[]>([]);

  const [grapExpanded, setGraphExpanded] = useLocalStorage<boolean>(
    "graphExpanded",
    false
  );

  useSubscribe<Recipe>(
    prefixCollection("recipes", tenant),
    setRecipes,
    setError,
    sortName
  );

  useSubscribe<GS>(
    prefixCollection("gravity", tenant),
    setGravitySamples,
    setError,
    sortGravitySamples,
    (item) => item.batchId === id
  );

  useSubscribe<Settings>(
    prefixCollection("settings", tenant),
    (items) => setSettings(items[0]),
    setError
  );

  const fetchGraphData = async () => {
    const response = await fetchItemsWithOrFilters<GraphLink>(
      prefixCollection("graphLinks", tenant),
      {
        or: [
          { field: "from", operator: "==", value: id },
          { field: "to", operator: "==", value: id },
        ],
      }
    );
    setGraphData(response);

    if (response.length > 0) {
      const allIds = response.flatMap((x) => [x.from, x.to]);
      const uniqueIds = Array.from(new Set(allIds));

      const invoicesFromGraph = await fetchItemsByIds<Invoice>(
        prefixCollection("invoices", tenant),
        uniqueIds
      );

      setInvoiceData(invoicesFromGraph);
    }
  };

  useEffect(() => {
    if (!editMode && id) {
      if (id === "new") {
        setBatch(initalBatch);
      } else {
        fetchBatch(id, tenant).then(setBatch);
      }
    }
  }, [editMode, id, tenant]);

  useEffect(() => {
    if (id && id !== "new") {
      fetchBatch(id, tenant).then((b) => {
        if (!b) return; // Handle potential null/undefined batch safely

        setQuantity(b.quantity?.toString() ?? "");
        setVolume(b.volume?.toString() ?? "");
        setYieldAmount(b.yield?.toString() ?? "");
        setExpectedYield(b.expectedYield?.toString() ?? "");
        setNotes(b.notes ?? "");
        setScore(b.score ?? -1);

        const startDate =
          (b.startDate as Timestamp)?.toDate()?.toISOString().split("T")[0] ??
          "";
        setStartDate(startDate);

        setBatch(b);
      });
    }
  }, [id, tenant]);

  useEffect(() => {
    if (batch && batch.recipeId && !batch.recipe) {
      fetchRecipe(batch.recipeId, tenant).then((r) => {
        if (r) {
          setBatch((prevBatch) => ({
            ...(prevBatch ?? initalBatch),
            recipe: r,
          }));
        }
      });
    }
  }, [batch, tenant]);

  useEffect(() => {
    if (id && id !== "new") fetchGraphData();
  }, [id]);

  const saveBatch = async () => {
    if (!batch) return;

    if (batch.id === "") {
      const docRef = await createBatch(
        {
          ...batch,
          quantity: parseInt(quantity),
          volume: parseFloat(volume),
          yield: parseFloat(yieldAmount),
          expectedYield: parseFloat(expectedYield),
        },
        tenant
      );
      navigate(`/batches/${docRef.id}`);
    } else {
      const newBatchData = {
        ...batch,
        quantity: parseInt(quantity),
        volume: parseFloat(volume),
        yield: parseFloat(yieldAmount),
        expectedYield: parseFloat(expectedYield),
        notes,
        score,
      };
      await updateBatch(batch.id, newBatchData, tenant);
      setBatch(newBatchData);
    }
  };

  const handleAddTasteSample = async (
    sample: Omit<TasteSample, "id" | "batchId">
  ) => {
    const newSample: TasteSample = {
      ...sample,
      id: v4(), // Unique ID for the sample
      batchId: batch?.id || "", // Link to the current batch
    };

    const updatedSamples = [...(batch?.tasteSamples ?? []), newSample];

    // Save updated samples to Firestore (optional)
    if (batch) {
      await updateBatch(
        batch.id,
        { ...batch, tasteSamples: updatedSamples },
        tenant
      );
      if (id && id !== "new") {
        fetchBatch(id, tenant).then(setBatch);
      }
    }
  };

  const cancelEdit = () => {
    setEditMode(false);
    if (id && id !== "new") {
      fetchBatch(id, tenant).then(setBatch);
    } else {
      navigate(`/batches`);
    }
  };

  const archiveBatch = async () => {
    if (!batch) return;
    const confirm = window.confirm(
      "Are you sure you want to archive this batch?"
    );
    if (!confirm) return;
    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, { status: "archived" });
    navigate(`/batches`);
  };

  const reActivateBatch = async () => {
    if (!batch) return;
    const confirm = window.confirm(
      "Are you sure you want to reactivate this batch?"
    );
    if (!confirm) return;
    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, { status: "planned" });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };

  const stepStart = async () => {
    if (!batch) return;
    const newRecipe = batch.recipe;
    if (!newRecipe) return;

    const newStep = newRecipe.steps[batch.currentStepIndex];
    if (!newStep) return;

    newStep.start = new Date();

    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, {
      stepStatus: "in-progress",
      status: "in-progress",
      recipe: newRecipe,
    });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };
  const stepComplete = async () => {
    if (!batch) return;

    const newRecipe = batch.recipe;
    if (!newRecipe) return;

    const newStep = newRecipe.steps[batch.currentStepIndex];
    if (!newStep) return;

    newStep.end = new Date();

    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, { stepStatus: "completed", recipe: newRecipe });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };
  const stepNext = async () => {
    if (!batch) return;
    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, {
      currentStepIndex: batch.currentStepIndex + 1,
      stepStatus: "not-started",
    });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };

  const stepPrevious = async () => {
    if (!batch) return;
    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, {
      currentStepIndex: batch.currentStepIndex - 1,
      stepStatus: "not-started",
    });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };

  const updateBatchStatus = async (
    status: "planned" | "in-progress" | "completed" | "archived"
  ) => {
    if (!batch) return;
    const itemRef = doc(db, prefixCollection("batches", tenant), batch.id);
    await updateDoc(itemRef, { status });
    if (id) fetchBatch(id, tenant).then(setBatch);
  };

  const handleDelete = async () => {
    if (window.confirm("Delete the batch?!") && id) {
      await deleteItem(prefixCollection("batches", tenant), id);
      navigate("/batches");
    }
  };

  const batchSize = (batch?.quantity ?? 1) / (batch?.recipe?.batchSize ?? 25);

  return (
    <>
      <ContentLayout
        disableOverlap
        header={
          <Header
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Button
                  onClick={() => navigate("/batches")}
                  iconName="angle-left"
                >
                  Back
                </Button>
                <Button
                  variant={editMode ? "primary" : "normal"}
                  onClick={() => {
                    if (editMode) {
                      saveBatch();
                    }
                    setEditMode((x) => !x);
                  }}
                  iconName={"edit"}
                >
                  {editMode ? "Save" : "Edit batch"}
                </Button>
                <Button
                  onClick={() => navigate("/batches/" + id + "/recipe/recipe")}
                  iconName="edit"
                >
                  Recipe
                </Button>
                <Button onClick={handleDelete} iconName="delete-marker">
                  Delete
                </Button>
                {editMode && <Button onClick={cancelEdit}>Cancel</Button>}
                {id &&
                  id !== "new" &&
                  (batch?.status !== "archived" ? (
                    <Button onClick={archiveBatch} iconName="download">
                      Archive
                    </Button>
                  ) : (
                    <Button onClick={reActivateBatch} iconName="upload">
                      Reactivate
                    </Button>
                  ))}
                {settings &&
                  settings.integrations
                    .map((x) => x.integrationName)
                    .includes("squarespace") && (
                    <Button
                      disabled={
                        !batch?.exciseDuty || !batch?.exciseDuty?.exciseDutyPaid
                      }
                      onClick={() => setFinishModal(true)}
                      iconName="status-positive"
                    >
                      Add to stock
                    </Button>
                  )}
              </SpaceBetween>
            }
            variant="h1"
            description={
              id === "new"
                ? "Create new batch"
                : (batch?.recipe?.name ?? "") +
                  " - " +
                  (batch?.recipe?.style ?? "")
            }
          >
            {id === "new" ? "Create new batch" : (batch?.name ?? "N/A")}
          </Header>
        }
      >
        <div style={{ marginTop: 10 }}>
          <ErrorBar error={error} setError={setError} />
          <Container
            header={
              <Header
                actions={
                  id &&
                  id !== "new" && (
                    <ButtonDropdown
                      onItemClick={({ detail }) => {
                        if (detail.id === "gravity")
                          navigate(`/batches/${id}/modal/gravity`);
                        else if (detail.id === "hydropi")
                          setShowHydroPiModal(true);
                        else if (detail.id === "taste") setShowTasteModal(true);
                        else if (detail.id === "excise")
                          setShowExciseDutyModal(true);
                        else if (detail.id === "link-invoices")
                          setShowGridLinkModel(true);
                        else
                          updateBatchStatus(
                            detail.id as
                              | "planned"
                              | "in-progress"
                              | "completed"
                              | "archived"
                          );
                      }}
                      items={[
                        {
                          text: "Status",
                          items: [
                            {
                              disabled: batch?.status === "planned",
                              text: "Planned",
                              id: "planned",
                            },
                            {
                              disabled: batch?.status === "in-progress",
                              text: "In progress",
                              id: "in-progress",
                            },
                            {
                              disabled: batch?.status === "completed",
                              text: "Completed",
                              id: "completed",
                            },
                            {
                              disabled: batch?.status === "archived",
                              text: "Archived",
                              id: "archived",
                            },
                          ],
                        },
                        {
                          text: "Sample",
                          items: [
                            { text: "Gravity", id: "gravity" },
                            { text: "Taste", id: "taste" },
                            { text: "HydroPi Config", id: "hydropi" },
                          ],
                        },

                        {
                          text: "Audit tracking",
                          items: [
                            {
                              text: "Excise duty",
                              id: "excise",
                            },
                            {
                              text: "Link to invoices",
                              id: "link-invoices",
                            },
                          ],
                        },
                      ]}
                      ariaLabel="Sample batch"
                      variant="inline-icon"
                    />
                  )
                }
              >
                General information
              </Header>
            }
          >
            <ColumnLayout columns={2} variant="text-grid">
              <FormField label="Batch name">
                {editMode ? (
                  <Input
                    value={batch?.name ?? ""}
                    onChange={({ detail }) =>
                      setBatch((prevBatch) => {
                        if (!prevBatch)
                          return {
                            ...initalBatch,
                            name: detail.value,
                          };
                        return { ...prevBatch, name: detail.value };
                      })
                    }
                  />
                ) : (
                  (batch?.name ?? "N/A")
                )}
              </FormField>
              <FormField label="Recipe">
                {editMode ? (
                  <Select
                    disabled={id !== "new"}
                    selectedOption={{
                      label:
                        (batch?.recipe?.name ?? "") +
                        " - " +
                        (batch?.recipe?.style ?? ""),
                      value: batch?.recipe?.id ?? "",
                    }}
                    onChange={({ detail }) =>
                      setBatch((prevBatch) => {
                        if (!prevBatch)
                          return {
                            ...initalBatch,
                            recipeId: detail.selectedOption.value ?? "",
                            recipe: recipes.find(
                              (r) => r.id === detail.selectedOption.value
                            ),
                          };
                        return {
                          ...prevBatch,
                          recipeId: detail.selectedOption.value ?? "",
                          recipe: recipes.find(
                            (r) => r.id === detail.selectedOption.value
                          ),
                        };
                      })
                    }
                    options={
                      recipes.map((recipe) => ({
                        label: recipe.name + " - " + recipe.style,
                        value: recipe.id,
                      })) ?? []
                    }
                  />
                ) : (
                  (batch?.recipe?.name ?? "") +
                  " - " +
                  (batch?.recipe?.style ?? "")
                )}
              </FormField>
              <FormField label="Status">
                <SpaceBetween size="m" direction="horizontal">
                  {editMode ? (
                    <Select
                      selectedOption={{
                        label: (
                          (batch?.status ?? "planned").charAt(0).toUpperCase() +
                          (batch?.status ?? "planned").slice(1)
                        ).replace("-", " "),
                        value: batch?.status ?? "planned",
                      }}
                      onChange={({ detail }) =>
                        setBatch((prevBatch) => {
                          if (!prevBatch)
                            return {
                              ...initalBatch,
                              status:
                                (detail.selectedOption?.value as
                                  | "planned"
                                  | "in-progress"
                                  | "completed"
                                  | "archived") ?? "planned",
                            };
                          return {
                            ...prevBatch,
                            status:
                              (detail.selectedOption.value as
                                | "planned"
                                | "in-progress"
                                | "completed"
                                | "archived") ?? "planned",
                          };
                        })
                      }
                      options={[
                        { label: "Planned", value: "planned" },
                        { label: "In progress", value: "in-progress" },
                        { label: "Completed", value: "completed" },
                        { label: "Archived", value: "archived" },
                      ]}
                    />
                  ) : (
                    <Badge
                      color={
                        batch?.status === "in-progress"
                          ? "blue"
                          : batch?.status === "completed"
                            ? "green"
                            : "grey"
                      }
                    >
                      {(
                        (batch?.status ?? "planned").charAt(0).toUpperCase() +
                        (batch?.status ?? "planned").slice(1)
                      ).replace("-", " ")}
                    </Badge>
                  )}
                </SpaceBetween>
              </FormField>
              <FormField
                label="Quantity (L)"
                info="Related to the recipe ingredients size. (Q/R)*I"
              >
                {editMode ? (
                  <Input
                    value={quantity}
                    onChange={({ detail }) => setQuantity(detail.value)}
                  />
                ) : (
                  (batch?.quantity ?? "N/A")
                )}
              </FormField>
              <FormField
                label="Volume (L)"
                info="What goes into your fermenter."
              >
                {editMode ? (
                  <Input
                    value={volume}
                    onChange={({ detail }) => setVolume(detail.value)}
                  />
                ) : (
                  (batch?.volume ?? "N/A")
                )}
              </FormField>
              <FormField label="Yield (L)" info="The amount that is drinkable.">
                {editMode ? (
                  <Input
                    value={yieldAmount}
                    onChange={({ detail }) => setYieldAmount(detail.value)}
                  />
                ) : (
                  (batch?.yield ?? "N/A")
                )}
              </FormField>

              <FormField
                label="Expected yield (L)"
                info="The amount that is expected to be drinkable."
              >
                {editMode ? (
                  <Input
                    value={expectedYield}
                    onChange={({ detail }) => setExpectedYield(detail.value)}
                  />
                ) : (
                  (batch?.expectedYield ?? "N/A")
                )}
              </FormField>

              <FormField
                label="Start date"
                constraintText="Use YYYY/MM/DD format."
              >
                {editMode ? (
                  <DatePicker
                    value={startDate}
                    onChange={({ detail }) => {
                      setStartDate(detail.value);
                      setBatch((prevBatch) => {
                        if (!prevBatch)
                          return {
                            ...initalBatch,
                            startDate: new Date(detail.value),
                          };
                        return {
                          ...prevBatch,
                          startDate: new Date(detail.value),
                        };
                      });
                    }}
                  />
                ) : startDate ? (
                  new Date(startDate)?.toLocaleDateString()
                ) : (
                  "No date yet"
                )}
              </FormField>
              <FormField
                label="End date"
                constraintText="Use YYYY/MM/DD format."
              >
                {editMode ? (
                  <DatePicker
                    value={endDate}
                    onChange={({ detail }) => {
                      setEndDate(detail.value);
                      setBatch((prevBatch) => {
                        if (!prevBatch)
                          return {
                            ...initalBatch,
                            endDate: new Date(detail.value),
                          };
                        return {
                          ...prevBatch,
                          endDate: new Date(detail.value),
                        };
                      });
                    }}
                  />
                ) : endDate ? (
                  new Date(endDate)?.toLocaleDateString()
                ) : (
                  (batch?.startDate
                    ? (addDaysBySteps(
                        toDate(batch.startDate),
                        batch?.recipe?.steps ?? []
                      )?.toLocaleDateString() ?? "No date yet")
                    : "No date yet") + " (expected)"
                )}
              </FormField>
              <FormField
                label="Notes"
                info="Add any additional notes for the batch."
              >
                {editMode ? (
                  <Input
                    value={notes}
                    onChange={({ detail }) => setNotes(detail.value)}
                  />
                ) : (
                  (batch?.notes ?? "N/A")
                )}
              </FormField>
              <FormField label="Score" info="Rate the batch, 1 to 5.">
                {editMode ? (
                  <Slider
                    onChange={({ detail }) => setScore(detail.value)}
                    value={score}
                    valueFormatter={(value) => `${value / 100}`}
                    step={5}
                    max={500}
                    min={0}
                    referenceValues={[100, 200, 300, 400, 500]}
                  />
                ) : score < 0 ? (
                  ""
                ) : (
                  <>
                    {renderStars(score)} {(score ?? -1) / 100}
                  </>
                )}
              </FormField>
            </ColumnLayout>
          </Container>
        </div>

        {batch && id !== "new" && (
          <Ingredients batch={batch} batchSize={batchSize} />
        )}

        {batch?.exciseDuty && <ExciseDuty exciseDuty={batch.exciseDuty} />}

        {batch &&
          (batch?.currentStepIndex ?? 0) <
            (batch?.recipe?.steps?.length ?? 99) && (
            <CurrentStep
              batch={batch}
              stepPrevious={stepPrevious}
              stepStart={stepStart}
              stepComplete={stepComplete}
              stepNext={stepNext}
            />
          )}

        {batch && <Steps batch={batch} setEditStep={setEditStep} />}

        {batch && <Cost batch={batch} />}

        {batch && graphData && graphData.length > 0 && (
          <div style={{ marginTop: 10 }}>
            <ExpandableSection
              variant="container"
              headerText="Audit trail"
              expanded={grapExpanded}
              onChange={({ detail }) => setGraphExpanded(detail.expanded)}
            >
              <GraphView
                flip
                id={batch.id}
                type="batch"
                graphData={graphData}
                labels={[
                  ...invoiceData.map((invoice) => {
                    return {
                      label: `${invoice.supplierName} ${invoice.invoiceNumber}`,
                      value: invoice.id,
                      type: "invoice",
                    };
                  }),
                  {
                    label: `${batch?.batchId} - ${batch?.name ?? ""}`,
                    value: batch.id,
                    type: "batch",
                  },
                ]}
              />
            </ExpandableSection>
          </div>
        )}

        {batch?.hydroPi && <HydroPi config={batch.hydroPi} />}

        {gravitySamples && gravitySamples.length > 0 && (
          <Gravity gravitySamples={gravitySamples} />
        )}

        {batch?.tasteSamples && <Taste batch={batch} />}
      </ContentLayout>

      <Modal
        visible={editStep > -1}
        onDismiss={() => setEditStep(-1)}
        header={batch?.recipe?.steps[editStep]?.name ?? ""}
        footer={
          <div style={{ float: "right" }}>
            <SpaceBetween size="m" direction="horizontal">
              <Button onClick={() => setEditStep(-1)}>Cancel</Button>
              <Button
                variant="primary"
                onClick={() => {
                  setEditStep(-1);
                  saveBatch();
                }}
              >
                Save
              </Button>
            </SpaceBetween>
          </div>
        }
      >
        <SpaceBetween size="m" direction="horizontal">
          <FormField label="Start date">
            <DatePicker
              value={
                batch?.recipe?.steps[editStep]?.start
                  ? (toDate(batch?.recipe?.steps[editStep]?.start ?? new Date())
                      ?.toISOString()
                      .split("T")[0] ?? "")
                  : ""
              }
              onChange={({ detail }) =>
                setBatch((prevBatch) => {
                  if (!prevBatch) return { ...initalBatch };
                  const newRecipe = prevBatch.recipe;
                  if (!newRecipe) return { ...initalBatch };
                  const newStep = newRecipe.steps[editStep];
                  if (!newStep) return { ...initalBatch };
                  newStep.start = new Date(detail.value);
                  return { ...prevBatch, recipe: newRecipe };
                })
              }
            />
          </FormField>
          <FormField label="End date">
            <DatePicker
              value={
                batch?.recipe?.steps[editStep]?.end
                  ? (toDate(batch?.recipe?.steps[editStep]?.end ?? new Date())
                      ?.toISOString()
                      .split("T")[0] ?? "")
                  : ""
              }
              onChange={({ detail }) =>
                setBatch((prevBatch) => {
                  if (!prevBatch) return { ...initalBatch };
                  const newRecipe = prevBatch.recipe;
                  if (!newRecipe) return { ...initalBatch };
                  const newStep = newRecipe.steps[editStep];
                  if (!newStep) return { ...initalBatch };
                  newStep.end = new Date(detail.value);
                  return { ...prevBatch, recipe: newRecipe };
                })
              }
            />
          </FormField>
        </SpaceBetween>
      </Modal>
      <Modal
        visible={!!id && id !== "new" && modal === "gravity"}
        onDismiss={() => navigate("/batches/" + id)}
        header="Gravity sample"
      >
        {batch?.recipe ? (
          <GravitySample
            recipe={batch?.recipe}
            step={batch?.currentStepIndex ?? 0}
          />
        ) : (
          "No recipe found"
        )}
      </Modal>
      <Modal
        visible={showHydroPiModal}
        onDismiss={() => setShowHydroPiModal(false)}
        header="HydroPi Config"
      >
        <HydroPiConfigModal
          batchId={id}
          onClose={() => setShowHydroPiModal(false)}
        />
      </Modal>
      <Modal
        visible={showExciseDutyModal}
        onDismiss={() => setShowExciseDutyModal(false)}
        header="Excise duty information"
      >
        <ExciseDutyModal
          batch={batch}
          onClose={() => setShowExciseDutyModal(false)}
        />
      </Modal>

      <TasteSampleModal
        visible={showTasteModal}
        onClose={() => setShowTasteModal(false)}
        onSave={handleAddTasteSample}
      />
      <UpdateQuantityModal
        visible={finishModal}
        onClose={() => setFinishModal(false)}
      />
      <GridLinkBatchModal
        showModal={showGridLinkModel}
        setShowModal={setShowGridLinkModel}
        graphData={graphData}
        invoiceData={invoiceData}
        onSave={() => fetchGraphData()}
      />
    </>
  );
};

export default BatchPage;
