export function distinctBy<T>(array: T[], field: keyof T): T[] {
    const seen = new Set<unknown>();
    return array.filter((item) => {
      const value = item[field];
      if (seen.has(value)) {
        return false;
      }
      seen.add(value);
      return true;
    });
  }