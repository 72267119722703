import { FC } from "react";
import "./Image.scss";

interface IImageProps {
  src?: string;
  alt?: string;
  onClick?: () => void;
  widthPart?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const Image: FC<IImageProps> = ({ src, alt, widthPart, onClick }) => {
  if (!src) return null;
  return (
    <img
      style={{ cursor: onClick ? "pointer" : "default" }}
      onClick={onClick}
      className={`generic-image width-${widthPart}`}
      src={src}
      alt={alt}
    />
  );
};

export default Image;
