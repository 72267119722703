import {
  Autosuggest,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FC, useState } from "react";
import { prefixCollection } from "../../utils/prefix";
import { GraphLink } from "../../models/GraphLink";
import { useParams } from "react-router-dom";
import { createItem, fetchItemsWithFilter, Filter } from "../../api/generic";
import { useAuthContext } from "../../contexts/AuthContext";
import { useDebounce } from "react-use";
import LoadingBar from "@cloudscape-design/chat-components/loading-bar";
import { Batch } from "../../models/Batch";

interface IGridLinkInvoiceModalProps {
  showModal: boolean;
  graphData: GraphLink[];
  batchData: Batch[];
  setShowModal: (val: boolean) => void;
  onSave?: () => void;
}

const GridLinkInvoiceModal: FC<IGridLinkInvoiceModalProps> = ({
  showModal,
  graphData,
  batchData,
  setShowModal,
  onSave,
}) => {
  const { id } = useParams();
  const { tenant } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBatches, setSelectedBatches] = useState<any[]>([]);

  const [batchOptions, setBatchOptions] = useState<any[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const fetchBatchOptions = async () => {
    if (!debouncedSearchTerm) {
      return;
    }

    const lastChar = debouncedSearchTerm.charAt(debouncedSearchTerm.length - 1);
    const nextChar = String.fromCharCode(lastChar.charCodeAt(0) + 1);
    const upperBound = debouncedSearchTerm.slice(0, -1) + nextChar;

    const filters: Filter<Batch>[] = [
      { field: "batchId", operator: ">=", value: debouncedSearchTerm },
      { field: "batchId", operator: "<", value: upperBound },
    ];

    const batches = await fetchItemsWithFilter<Batch>(
      prefixCollection("batches", tenant),
      filters
    );

    const exludeThese = batchData.map((x) => x.id);

    setBatchOptions(
      batches
        .filter((x) => !exludeThese.includes(x.id))
        .map((batch) => ({
          label: `${batch.batchId} - ${batch.name}`,
          value: batch.id,
        }))
    );
  };

  const handelSaveLinks = async () => {
    setIsLoading(true);
    const itemsToSave: GraphLink[] = selectedBatches.map((batch) => ({
      from: id ?? "",
      to: batch.value ?? "",
      type: ["invoice", "batch"],
      createdAt: new Date(),
    }));

    const promises = itemsToSave.map((item) =>
      createItem<GraphLink>(prefixCollection("graphLinks", tenant), item)
    );

    await Promise.all(promises);

    setSelectedBatches([]);
    setShowModal(false);
    setBatchOptions([]);
    if (onSave) onSave();
    setIsLoading(false);
  };

  useDebounce(
    () => {
      fetchBatchOptions();
    },
    1000,
    [debouncedSearchTerm]
  );

  return (
    <Modal
      header="Link invoice to batches"
      visible={showModal}
      onDismiss={() => setShowModal(false)}
      footer={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button variant="normal" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handelSaveLinks()}>
            Save
          </Button>
        </div>
      }
    >
      {isLoading ? (
        <LoadingBar variant="gen-ai" />
      ) : (
        <SpaceBetween size="m" direction="vertical">
          <Autosuggest
            onChange={({ detail }) => setDebouncedSearchTerm(detail.value)}
            value={debouncedSearchTerm}
            options={batchOptions}
            ariaLabel="Search for batches by batch ID"
            placeholder="Enter batch ID"
            empty="No matches found"
            onSelect={({ detail }) => {
              setSelectedBatches([
                ...selectedBatches.filter(
                  (x) => x.value !== detail.selectedOption?.value
                ),
                detail.selectedOption,
              ]);
              setDebouncedSearchTerm("");
            }}
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
            {[
              ...selectedBatches,
              ...graphData
                .filter((x) => x.from !== id)
                .map((from) => {
                  return {
                    value: from.from,
                    label: `${batchData.find((x) => x.id === from.from)?.batchId} ${batchData.find((x) => x.id === from.from)?.name}`,
                  };
                }),
              ...graphData
                .filter((x) => x.to !== id)
                .map((to) => {
                  return {
                    value: to.to,
                    label: `${batchData.find((x) => x.id === to.to)?.batchId} ${batchData.find((x) => x.id === to.to)?.name}`,
                  };
                }),
            ].map((batch) => (
              <Button
                key={batch.value}
                iconName="close"
                iconAlign="right"
                disabled={!selectedBatches.some((x) => x.value === batch.value)}
                onClick={() =>
                  setSelectedBatches((x) =>
                    x.filter((b) => b.value !== batch.value)
                  )
                }
              >
                {batch.label}
              </Button>
            ))}
          </div>
        </SpaceBetween>
      )}
    </Modal>
  );
};

export default GridLinkInvoiceModal;
