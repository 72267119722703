import {
  Box,
  Button,
  ColumnLayout,
  ExpandableSection,
  Icon,
} from "@cloudscape-design/components";
import { FC } from "react";
import { useLocalStorage } from "react-use";
import { Batch } from "../models/Batch";
import { addDaysBySteps, toDate } from "../utils/timestamp";

interface IStepsProps {
  batch: Batch;
  setEditStep: (index: number) => void;
}

const Steps: FC<IStepsProps> = ({ batch, setEditStep }) => {
  const [stepsExpanded, setStepsExpanded] = useLocalStorage<boolean>(
    "stepsExpanded",
    false
  );

  return (
    <div style={{ marginTop: 10 }}>
      <ExpandableSection
        variant="container"
        headerText="Steps"
        expanded={stepsExpanded}
        onChange={({ detail }) => setStepsExpanded(detail.expanded)}
      >
        <ColumnLayout columns={1} variant="text-grid">
          {batch?.recipe?.steps.map((step, i) => {
            let thisStepStart = step.start;
            let thisStepEnd = step.end;

            // Calculate start and end dates if missing
            if (!thisStepStart && batch?.startDate) {
              thisStepStart = addDaysBySteps(
                toDate(batch.startDate),
                (batch?.recipe?.steps ?? []).slice(0, i)
              );
            } else if (thisStepStart) thisStepStart = toDate(thisStepStart);

            if (!thisStepEnd && batch?.startDate) {
              thisStepEnd = addDaysBySteps(
                toDate(batch.startDate),
                (batch?.recipe?.steps ?? []).slice(0, i + 1)
              );
            } else if (thisStepEnd) thisStepEnd = toDate(thisStepEnd);

            const duration =
              Math.round(
                (thisStepEnd?.getTime() ?? 0) - (thisStepStart?.getTime() ?? 0)
              ) /
              (1000 * 60 * 60 * 24);

            return (
              <div key={`step-${i}`} style={{ display: "flex", gap: 8 }}>
                <Icon
                  key={`icon-${i}`}
                  name={
                    (batch?.currentStepIndex ?? 0) === i
                      ? "caret-right-filled"
                      : (batch?.currentStepIndex ?? 0) >= i
                        ? "check"
                        : "status-pending"
                  }
                />
                <Box key={`box-index-${i}`} variant="awsui-key-label">
                  {i + 1}.
                </Box>
                <Box key={`box-name-${i}`} variant="awsui-key-label">
                  {step.name}
                </Box>
                <Box key={`box-duration-${i}`}>{duration.toFixed(0)} days</Box>
                <Box
                  key={`box-start-${i}`}
                  variant={step?.start ? "awsui-key-label" : undefined}
                >
                  {thisStepStart?.toLocaleDateString()}
                </Box>
                {" - "}
                <Box
                  key={`box-end-${i}`}
                  variant={step?.end ? "awsui-key-label" : undefined}
                >
                  {thisStepEnd?.toLocaleDateString()}
                </Box>
                <Button
                  key={`button-${i}`}
                  onClick={() => setEditStep(i)}
                  variant="inline-icon"
                  iconName="status-pending"
                />
              </div>
            );
          })}
        </ColumnLayout>
      </ExpandableSection>
    </div>
  );
};

export default Steps;
