import { useEffect } from "react";

import { subscribeToChanges } from "../api/generic";

export type Subscribable<T> = T & { id?: string };

const useSubscribe = <T extends { id?: string }>(
  collectionName: string,
  onUpdate: (items: T[]) => void,
  onError: (error: Error) => void,
  sortFunction?: (a: T, b: T) => number, // Optional sort function
  filterFunction?: (item: T) => boolean // Filter
): void => {

  useEffect(() => {
    const unsubscribe = subscribeToChanges<T>(
      collectionName,
      (items) => {
        const filteredItems = filterFunction ? items.filter(filterFunction) : items;
        const sortedItems = sortFunction ? filteredItems.sort(sortFunction) : filteredItems;
        onUpdate(sortedItems);
      },
      onError
    );
  
    return () => {
      unsubscribe();
    };
  }, [collectionName]);
  
};
export default useSubscribe;
