import { use, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  SideNavigation,
  SideNavigationProps,
} from "@cloudscape-design/components";

import { subscribeToBatchesByStatus } from "../api/batches";
import { MenuItem } from "../api/menu";
import { useAuthContext } from "../contexts/AuthContext";
import { defaultMenuItems } from "../api/init";

const Sidebar = () => {
  const { currentUser, userObject, tenant, tier } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [rankedItems, setRankedItems] = useState<MenuItem[]>(
    defaultMenuItems
      .filter((x) => (x?.tier ?? 0) <= tier)
      .map((x) => ({ title: x.title, type: x.type, href: x.href }) as MenuItem)
  );
  const [, setShopping] = useState(false);

  const [activeHref, setActiveHref] = useState(location.pathname);

  useEffect(() => {
    setActiveHref(location.pathname);
  }, [location]);

  useEffect(() => {
    const unsubscribe = subscribeToBatchesByStatus(
      ["planned"],
      tenant,
      (batches) => setShopping(batches.length > 0)
    );
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (activeHref !== location.pathname) {
      navigate(activeHref);
    }
  }, [activeHref]);

  useEffect(() => {
    setRankedItems(
      defaultMenuItems
        .filter((x) => (x?.tier ?? 0) <= tier)
        .map(
          (x) => ({ title: x.title, type: x.type, href: x.href }) as MenuItem
        )
    );
  }, [tier]);

  return (
    <>
      <SideNavigation
        header={{
          href: "/",
          logo: { alt: "logo", src: "/logo_2.png" },
        }}
        activeHref={activeHref}
        onFollow={(event) => {
          if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
          }
        }}
        items={
          !currentUser || !userObject?.approved
            ? []
            : [
                {
                  type: "link",
                  text: "Home",
                  href: "/",
                },
                ...rankedItems.map((x) => {
                  return {
                    type: "link",
                    text: x.title,
                    href: x.href ?? "",
                  } as SideNavigationProps.Item;
                }),
                {
                  type: "link",
                  text: "Settings",
                  href: "/settings",
                } as SideNavigationProps.Item,
              ]
        }
      />
    </>
  );
};

export default Sidebar;
