import { v4 as uuidv4 } from "uuid"; 

export interface SqInventoryItem {
  variantId: string; // Unique ID for the InventoryItem
  sku: string; // Stock keeping unit (SKU) code
  descriptor: string; // Description generated using product title and attributes
  isUnlimited: boolean; // Indicates if stock is tracked
  quantity: number; // Current amount in stock or last known stock amount  
}

export enum OperationType {
  Increment = "INCREMENT",
  Decrement = "DECREMENT",
  SetFinite = "SET_FINITE",
  SetUnlimited = "SET_UNLIMITED",
}

export interface SqAdjustment {
  variantId: string;
  quantity: number;
  operation: OperationType;
}
export interface SqPagination {
  hasNextPage: boolean; // Indicates if another page of data is available
  nextPageCursor: string; // Cursor for the next page of data
  nextPageUrl: string; // URL to request the next page of data
}

export interface SqInventoryResponse {
  inventory: SqInventoryItem[]; // Array of InventoryItem resources
  pagination: SqPagination; // Pagination details for iterating on InventoryItems
}

export interface SqProductsResponse {
  products: SqProduct[];
  pagination: SqPagination;
}

export interface SqProduct {
  id: string;
  name: string;
  type: 'PHYSICAL' | 'DIGITAL';
  url: string;
  variants?: SqVariant[];
  pricing?: SqPricing;
  tags?: string[];
  description?: string;
  images?: SqProductImages[];
}

export interface SqVariant {
  id: string;
  sku: string;
  pricing: SqPricing;
  stock?: SqStock;
  image?: SqImage;
  attributes?: { [key: string]: string };
}

export interface SqProductImages {
  id: string;
  altText: string;
  url: string;
  originalSize: { width: number; height: number };
  availableFormats: string[];
}

export interface SqImage {
  id: string;
  title: string;
  url: string;
  originalSize: { width: number; height: number };
  availableFormats: string[];
}

export interface SqStock {
  quantity: number;
  unlimited: boolean;
}

export interface SqPricing {
  basePrice: SqMoney;
  salePrice?: SqMoney;
  onSale: boolean;
}

export interface SqMoney {
  currency: string;
  value: string;
}

export interface InventoryAdjustmentPayload {
  incrementOperations?: { variantId: string; quantity: number }[];
  decrementOperations?: { variantId: string; quantity: number }[];
  setFiniteOperations?: { variantId: string; quantity: number }[];
  setUnlimitedOperations?: string[];
}

const proxy = (url: string, apiKey: string) =>
  `https://proxyfunction-ogbfqlrosa-ew.a.run.app?url=${encodeURIComponent(
    url
  )}&apiKey=${apiKey}`;

const squareUrls = {
  retrieveAllInventory: (api: string, cursor?: string) =>
    `https://api.squarespace.com/${api}/commerce/inventory${
      cursor ? "?cursor=" + cursor : ""
    }`,
  adjustInventory:
    "https://api.squarespace.com/1.0/commerce/inventory/adjustments",

    retrieveAllProducts: (api: string, cursor?: string) =>
      `https://api.squarespace.com/${api}/commerce/products${
        cursor ? "?cursor=" + cursor : ""
      }`,

};

export const createInventoryAdjustmentPayload = (
  adjustments: SqAdjustment[]
): InventoryAdjustmentPayload => {
  const incrementOperations: { variantId: string; quantity: number }[] = [];
  const decrementOperations: { variantId: string; quantity: number }[] = [];
  const setFiniteOperations: { variantId: string; quantity: number }[] = [];
  const setUnlimitedOperations: string[] = [];

  adjustments.forEach((adjustment) => {
    switch (adjustment.operation) {
      case OperationType.Increment:
        incrementOperations.push({
          variantId: adjustment.variantId,
          quantity: adjustment.quantity,
        });
        break;
      case OperationType.Decrement:
        decrementOperations.push({
          variantId: adjustment.variantId,
          quantity: adjustment.quantity,
        });
        break;
      case OperationType.SetFinite:
        setFiniteOperations.push({
          variantId: adjustment.variantId,
          quantity: adjustment.quantity,
        });
        break;
      case OperationType.SetUnlimited:
        setUnlimitedOperations.push(adjustment.variantId);
        break;
    }
  });

  return {
    incrementOperations: incrementOperations.length
      ? incrementOperations
      : undefined,
    decrementOperations: decrementOperations.length
      ? decrementOperations
      : undefined,
    setFiniteOperations: setFiniteOperations.length
      ? setFiniteOperations
      : undefined,
    setUnlimitedOperations: setUnlimitedOperations.length
      ? setUnlimitedOperations
      : undefined,
  };
};

export const retrieveAllProducts = async (apiKey: string) => {
  const url = proxy(squareUrls.retrieveAllProducts("1.0"), apiKey);

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    // console.log("retrieveAllProducts", await response.json());

    const data = (await response.json()) as SqProductsResponse;

    // Sort the inventory items by descriptor
    data.products.sort((a, b) => a.name.localeCompare(b.name));

    return data;
  } catch (error) {
    console.error("Error fetching inventory:", error);
    return null;
  }
}

export const retrieveAllInventory = async (apiKey: string) => {
  const url = proxy(squareUrls.retrieveAllInventory("1.0"), apiKey);

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    // console.log("retrieveAllInventory", await response.json());

    const data = (await response.json()) as SqInventoryResponse;

    // Sort the inventory items by descriptor
    data.inventory.sort((a, b) => a.descriptor.localeCompare(b.descriptor));

    return data;
  } catch (error) {
    console.error("Error fetching inventory:", error);
    return null;
  }
};

export const pushInventoryToApi = async (
  payload: InventoryAdjustmentPayload,
  apiKey: string
) => {
  const url = proxy(squareUrls.adjustInventory, apiKey);
  const idempotencyKey = uuidv4();

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Idempotency-Key": idempotencyKey,
      },
      body: JSON.stringify({ items: payload }), // Add the payload to the body
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error pushing inventory to API:", error);
    throw error;
  }
};
