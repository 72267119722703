import { FC } from "react";
import { GraphLink, GraphLinkLabel } from "../../models/GraphLink";
import { ReactFlow, Controls, Background, Position } from "@xyflow/react";
import "@xyflow/react/dist/style.css";

interface IGraphViewProps {
  id: string;
  type: string;
  graphData?: GraphLink[];
  labels?: GraphLinkLabel[];
  flip?: boolean;
}

const GraphView: FC<IGraphViewProps> = ({
  id,
  type,
  graphData,
  labels,
  flip,
}) => {
  const getStyle = (label: any) => {
    let backgroundColor = "lightgray";
    const color = "black";
    switch (label?.type) {
      case "batch":
        backgroundColor = "#75cfff";
        break;
      case "invoice":
        backgroundColor = "#dedee3";
        break;
      default:
        break;
    }
    return {
      backgroundColor,
      color,
    };
  };

  const nodes = [
    {
      id: id,
      position: { x: 0, y: 0 },
      data: {
        label: labels?.find((l) => l.value === id)?.label,
      },
      style: {
        ...getStyle(labels?.find((l) => l.value === id)),
        fontWeight: 600,
      },
      sourcePosition: flip ? Position.Left : Position.Right,
    },
    ...(graphData || []).flatMap((link, i) => {
      const labelMatch = labels?.find(
        (l) => (l.value === link.to || l.value === link.from) && l.type !== type
      );

      if (!labelMatch) return []; // ⬅️ return empty array to skip
      return [
        {
          id: link.type[0] === type ? link.to : link.from,
          position: {
            x: (flip ? -1 : 1) * 250,
            y: i * 100 - (graphData?.length ?? 0) * 20,
          },
          data: {
            label: labelMatch.label,
          },
          targetPosition: flip ? Position.Right : Position.Left,
          style: getStyle(labelMatch),
        },
      ];
    }),
  ];

  const edges = (graphData || []).map((link) => ({
    id: `${link.from}-${link.to}`,
    source: link.type[0] === type ? link.from : link.to,
    target: link.type[0] === type ? link.to : link.from,
    type: "smoothstep",
    animated: true,
    label: labels?.find((l) => l.value === link.type[0])?.label,
  }));

  return (
    <div
      style={{
        height: "500px",
        width: "100%",
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "10px",
      }}
    >
      <ReactFlow nodes={nodes} edges={edges} fitView>
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default GraphView;
