import { FC } from "react";
import { Batch } from "../models/Batch";
import { useLocalStorage } from "react-use";
import {
  Box,
  ColumnLayout,
  ExpandableSection,
  SpaceBetween,
} from "@cloudscape-design/components";
import { costPerBottle, costPerUnit, costTotal } from "../utils/cost";

interface ICostProps {
  batch: Batch;
}

const Cost: FC<ICostProps> = ({ batch }) => {
  const [costExpanded, setCostExpanded] = useLocalStorage<boolean>(
    "costExpanded",
    false
  );

  return (
    <div style={{ marginTop: 10 }}>
      <ExpandableSection
        variant="container"
        headerText="Cost"
        expanded={costExpanded}
        onChange={({ detail }) => setCostExpanded(detail.expanded)}
      >
        {/* Cost overview */}
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="m" direction="horizontal">
            <Box variant="awsui-key-label">Total cost</Box>
            <Box>€ {batch ? costTotal(batch).toFixed(2) : "0.00"}</Box>
          </SpaceBetween>
          <SpaceBetween size="m" direction="horizontal">
            <Box variant="awsui-key-label">Cost per liter</Box>
            <Box>€ {batch ? costPerUnit(batch).toFixed(2) : "0.00"}</Box>
          </SpaceBetween>

          <SpaceBetween size="m" direction="horizontal">
            <Box variant="awsui-key-label">Cost per bottle (0.75)</Box>
            <Box>€ {batch ? costPerBottle(batch, "1").toFixed(2) : "0.00"}</Box>
          </SpaceBetween>
          <SpaceBetween size="m" direction="horizontal">
            <Box variant="awsui-key-label">Cost per bottle (0.33)</Box>
            <Box>€ {batch ? costPerBottle(batch, "2").toFixed(2) : "0.00"}</Box>
          </SpaceBetween>
        </ColumnLayout>
      </ExpandableSection>
    </div>
  );
};

export default Cost;
