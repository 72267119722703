import { FC } from "react";
import { Batch } from "../models/Batch";
import {
  Button,
  ExpandableSection,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useLocalStorage } from "react-use";

interface ICurrentStepProps {
  batch: Batch;

  stepPrevious: () => void;
  stepStart: () => void;
  stepComplete: () => void;
  stepNext: () => void;
}

const CurrentStep: FC<ICurrentStepProps> = ({
  batch,
  stepPrevious,
  stepStart,
  stepComplete,
  stepNext,
}) => {
  const [currentStepExpanded, setCurrentStepExpanded] =
    useLocalStorage<boolean>("currentStepExpanded", false);

  return (
    <div style={{ marginTop: 10 }}>
      <ExpandableSection
        headerActions={
          <SpaceBetween size="m" direction="horizontal">
            {batch?.currentStepIndex !== 0 && (
              <Button
                variant="link"
                iconName="angle-left"
                onClick={stepPrevious}
              />
            )}
            {batch?.stepStatus === "not-started" && (
              <Button
                variant="primary"
                iconName="caret-right-filled"
                onClick={stepStart}
              />
            )}
            {batch?.stepStatus === "in-progress" && (
              <Button
                variant="primary"
                iconName="status-pending"
                onClick={stepComplete}
              />
            )}
            {batch?.stepStatus === "completed" && (
              <Button variant="primary" iconName="check" onClick={stepNext} />
            )}
          </SpaceBetween>
        }
        variant="container"
        headerDescription={
          batch?.stepStatus === "completed"
            ? "Done"
            : batch?.stepStatus === "in-progress"
              ? "In progress"
              : "Not started"
        }
        headerText={
          "Current step - (" +
          ((batch?.currentStepIndex ?? 0) + 1) +
          ". " +
          (batch?.recipe?.steps[batch?.currentStepIndex ?? 0]?.name ?? "") +
          ")"
        }
        expanded={currentStepExpanded}
        onChange={({ detail }) => setCurrentStepExpanded(detail.expanded)}
      >
        {batch?.recipe?.steps[batch?.currentStepIndex ?? 0]?.description ?? ""}
      </ExpandableSection>
    </div>
  );
};

export default CurrentStep;
