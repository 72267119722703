import { FC } from "react";
import { Batch } from "../models/Batch";
import {
  Box,
  ColumnLayout,
  ExpandableSection,
  LineChart,
  SpaceBetween,
} from "@cloudscape-design/components";
import { GravitySample } from "../models/Sampling";
import { useLocalStorage } from "react-use";
import { toDate } from "../utils/timestamp";

interface IGravityProps {
  gravitySamples: GravitySample[];
}

const Gravity: FC<IGravityProps> = ({ gravitySamples }) => {
  const [gravityExpanded, setGravityExpanded] = useLocalStorage<boolean>(
    "gravityExpanded",
    false
  );

  return (
    <div style={{ marginTop: 10 }}>
      <ExpandableSection
        variant="container"
        headerText="Gravity"
        expanded={gravityExpanded}
        onChange={({ detail }) => setGravityExpanded(detail.expanded)}
      >
        <ColumnLayout columns={1} variant="text-grid">
          {gravitySamples && gravitySamples.length > 0 && (
            <LineChart
              xScaleType="time"
              series={[
                {
                  title: "OG",
                  type: "line",
                  data: gravitySamples.map((sample) => ({
                    x: toDate(sample.date),
                    y: parseFloat(sample.og),
                  })),
                },
                {
                  title: "SG",
                  type: "line",
                  data: gravitySamples.map((sample) => ({
                    x: toDate(sample.date),
                    y: parseFloat(sample.sg),
                  })),
                },
                {
                  title: "FG",
                  type: "line",
                  data: gravitySamples.map((sample) => ({
                    x: toDate(sample.date),
                    y: parseFloat(sample.fg),
                  })),
                },
              ]}
              yDomain={[0.98, 1.12]}
              xDomain={
                [
                  toDate(gravitySamples[0].date),
                  toDate(gravitySamples[gravitySamples.length - 1].date),
                ] as [Date, Date]
              }
            />
          )}
          <div style={{ marginTop: 10 }}>
            {gravitySamples.map((sample, i) => {
              return (
                <SpaceBetween
                  key={toDate(sample.date).toISOString() + i}
                  size="m"
                  direction="horizontal"
                >
                  <Box variant="awsui-key-label">
                    {toDate(sample.date).toLocaleDateString()}
                  </Box>
                  <Box>{sample.og}</Box>
                  <Box variant="awsui-key-label">{sample.sg}</Box>
                  <Box>{sample.fg}</Box>
                  <Box>{sample.temperature}</Box>
                  <Box>{sample.comment}</Box>
                </SpaceBetween>
              );
            })}
          </div>
        </ColumnLayout>
      </ExpandableSection>
    </div>
  );
};

export default Gravity;
