import { ContentLayout, Header } from "@cloudscape-design/components";
import React from "react";

const Audit = () => {
  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h1"
          description={
            "Hint: The Audit tracker can find all batches an invoice is linked to or all invoices a batch is linked to."
          }
        >
          Audit tracker
        </Header>
      }
    >
      Test
    </ContentLayout>
  );
};

export default Audit;
