import { collection, doc, getDocs, setDoc } from "@firebase/firestore";

import { db } from "../config/firebase";
import { MenuItem } from "./menu";

export const defaultMenuItems: MenuItem[] = [
  { id: "inventory", title: "Inventory", type: "link", href: "/inventory", ranking: 1500, tier: 1 },
  { id: "recipes", title: "Recipes", type: "link", href: "/recipes", ranking: 1500, tier: 1 },
  { id: "batches", title: "Batches", type: "link", href: "/batches", ranking: 1500, tier: 1 },
  {
    id: "shopping-list",
    title: "Shopping List",
    type: "link",
    href: "/shopping-list",
    ranking: 1500,
    tier: 1,
  },
  { id: "stock", title: "Stock", type: "link", href: "/stock", ranking: 1500, tier: 2 },
  { id: "planning", title: "Planning", type: "link", href: "/plan", ranking: 1500, tier: 2 },
  { id: "tools", title: "Tools", type: "link", href: "/tools", ranking: 1500, tier: 1 },
  { id: "invoices", title: "Invoices", type: "link", href: "/invoices", ranking: 1500, tier: 3 },
  
  { id: "audit", title: "Audit", type: "link", href: "/audit", ranking: 1500, tier: 3 },
  { id: "suggestions", title: "Suggestions", type: "link", href: "/suggestions", ranking: 1500, tier: 1 },
];

export async function initializeMenuItems(): Promise<void> {
  try {
    const menuCollection = collection(db, "menuItems");
    const existingItemsSnapshot = await getDocs(menuCollection);
    const existingItems = new Set();
    existingItemsSnapshot.forEach((doc) => {
      // Assuming we use 'href' as the unique identifier for each menu item
      existingItems.add(doc.data().href);
    });

    for (const item of defaultMenuItems) {
      if (!existingItems.has(item.href)) {
        // Generate an ID from the href, replacing slashes with dashes, or use a custom method
        const itemId = (item?.href ?? "/").replace(/\//g, "-");
        const docRef = doc(menuCollection, itemId);
        await setDoc(docRef, item);
      }
    }
  } catch (error) {
    console.error("Error initializing menu items:", error);
    throw error; // Re-throw the error after logging it
  }
}
