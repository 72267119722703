import {
  Autosuggest,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import { useDebounce } from "react-use";
import LoadingBar from "@cloudscape-design/chat-components/loading-bar";
import { GraphLink } from "../../models/GraphLink";
import { Invoice } from "../../models/Invoice";
import { createItem, fetchItemsWithFilter, Filter } from "../../api/generic";
import { prefixCollection } from "../../utils/prefix";
import { distinctBy } from "../../utils/array";

interface IGridLinkBatchModal {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  graphData: GraphLink[];
  invoiceData: Invoice[];
  onSave?: () => void;
}

const GridLinkBatchModal: FC<IGridLinkBatchModal> = ({
  showModal,
  setShowModal,
  graphData,
  invoiceData,
  onSave,
}) => {
  const { id } = useParams();
  const { userObject, tenant } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedInvoices, setSelectedInvoices] = useState<any[]>([]);
  const [invoiceOptions, setInvoiceOptions] = useState<any[]>([]);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const fetchInvoiceOptions = async () => {
    if (!debouncedSearchTerm) return;

    const lastChar = debouncedSearchTerm.charAt(debouncedSearchTerm.length - 1);
    const nextChar = String.fromCharCode(lastChar.charCodeAt(0) + 1);
    const upperBound = debouncedSearchTerm.slice(0, -1) + nextChar;

    const filters1: Filter<Invoice>[] = [
      { field: "supplierName", operator: ">=", value: debouncedSearchTerm },
      { field: "supplierName", operator: "<", value: upperBound },
    ];

    const result = await fetchItemsWithFilter<Invoice>(
      prefixCollection("invoices", tenant),
      filters1
    );

    const filters2: Filter<Invoice>[] = [
      { field: "invoiceNumber", operator: ">=", value: debouncedSearchTerm },
      { field: "invoiceNumber", operator: "<", value: upperBound },
    ];

    const result2 = await fetchItemsWithFilter<Invoice>(
      prefixCollection("invoices", tenant),
      filters2
    );

    const exludeThese = invoiceData.map((x) => x.id);

    const iOptions = distinctBy([...result, ...result2], "id")
      .filter((x) => !exludeThese.includes(x.id))
      .map((invoice) => {
        return {
          label: `${invoice.supplierName} ${invoice.invoiceNumber} - ${invoice.invoiceDate} - [${invoice.totalAmountInclVat} ${invoice.currency}]`,
          value: invoice.id,
        };
      });

    setInvoiceOptions(iOptions);
  };

  const handelSaveLinks = async () => {
    setIsLoading(true);

    const itemsToSave: GraphLink[] = selectedInvoices.map((invoice) => ({
      from: id ?? "",
      to: invoice.value ?? "",
      type: ["batch", "invoice"],
      createdAt: new Date(),
      linkedBy: userObject?.id,
    }));

    const promises = itemsToSave.map((item) =>
      createItem<GraphLink>(prefixCollection("graphLinks", tenant), item)
    );
    await Promise.all(promises);

    setSelectedInvoices([]);
    setInvoiceOptions([]);
    if (onSave) onSave();
    setShowModal(false);
    setIsLoading(false);
  };

  useDebounce(
    () => {
      fetchInvoiceOptions();
    },
    1000,
    [debouncedSearchTerm]
  );

  return (
    <Modal
      visible={showModal}
      onDismiss={() => setShowModal(false)}
      header={"Link to invoices"}
      footer={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button variant="normal" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handelSaveLinks()}>
            Save
          </Button>
        </div>
      }
    >
      {isLoading ? (
        <LoadingBar variant="gen-ai" />
      ) : (
        <SpaceBetween size="m" direction="vertical">
          <Autosuggest
            onChange={({ detail }) => setDebouncedSearchTerm(detail.value)}
            value={debouncedSearchTerm}
            options={invoiceOptions}
            ariaLabel="Search for invoices"
            placeholder="Enter supplier name or invoice number"
            empty="No matches found"
            onSelect={({ detail }) => {
              setSelectedInvoices([
                ...selectedInvoices.filter(
                  (x) => x.value !== detail.selectedOption?.value
                ),
                detail.selectedOption,
              ]);
              setDebouncedSearchTerm("");
            }}
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
            {[
              ...selectedInvoices,
              ...graphData
                .filter((x) => x.from !== id)
                .map((from) => {
                  return {
                    value: from.from,
                    label: `${invoiceData.find((x) => x.id === from.from)?.supplierName} ${invoiceData.find((x) => x.id === from.from)?.invoiceNumber}`,
                  };
                }),
              ...graphData
                .filter((x) => x.to !== id)
                .map((to) => {
                  return {
                    value: to.to,
                    label: `${invoiceData.find((x) => x.id === to.to)?.supplierName} ${invoiceData.find((x) => x.id === to.to)?.invoiceNumber}`,
                  };
                }),
            ].map((invoice) => (
              <Button
                key={invoice.value}
                iconName="close"
                iconAlign="right"
                disabled={
                  !selectedInvoices.some((x) => x.value === invoice.value)
                }
                onClick={() =>
                  setSelectedInvoices((x) =>
                    x.filter((b) => b.value !== invoice.value)
                  )
                }
              >
                {invoice.label}
              </Button>
            ))}
          </div>
        </SpaceBetween>
      )}
    </Modal>
  );
};

export default GridLinkBatchModal;
