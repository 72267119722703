export interface Change {
    title: string;
    description: string;
    note?: string;
  }
  
  export const changes: Change[] = [
    {
        title: "New taste features",
        description: "We've added tools to help you track taste more easily.",
        note: "You can find this features in the batch details section."
    },    
    {
        title: "Cleaner inventory management",
        description: "We removed unused code to make stock tracking smoother and more efficient.",
        note: "No action needed—just enjoy a faster experience!"
    },
    {
        title: "Better product images",
        description: "We improved the way product images are displayed and linked to Squarespace for easier browsing.",
        note: "You'll see sharper images in your product list."
    },
    {
        title: "More reliable data updates",
        description: "We've improved the way subscription updates work, ensuring more accurate data refreshes.",
        note: "Changes to batch details should now appear instantly."
    },
    {
        title: "Smarter inventory sorting",
        description: "Filtering and sorting logic has been streamlined to help you find items faster.",
        note: "Search and filtering should now work more smoothly."
    }
];

  
  