import {
  Box,
  ExpandableSection,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useLocalStorage } from "react-use";
import { Batch } from "../models/Batch";
import { FC } from "react";
import TasteVisualization from "../components/TasteRadarChart";

interface ITasteProps {
  batch: Batch;
}

const Taste: FC<ITasteProps> = ({ batch }) => {
  const [tasteExpanded, setTasteExpanded] = useLocalStorage<boolean>(
    "tasteExpanded",
    false
  );

  return (
    <div style={{ marginTop: 10 }}>
      <ExpandableSection
        variant="container"
        headerText="Taste"
        expanded={tasteExpanded}
        onChange={({ detail }) => setTasteExpanded(detail.expanded)}
      >
        <SpaceBetween size="m" direction="horizontal">
          <TasteVisualization batch={batch} />
          <SpaceBetween size="m">
            {batch.tasteSamples &&
              batch.tasteSamples
                .filter((taste) => taste.additionalNotes)
                .map((taste) => (
                  <SpaceBetween size="m" key={taste.id}>
                    <Box variant="awsui-key-label">{taste.stage}</Box>
                    <Box>{taste.additionalNotes}</Box>
                  </SpaceBetween>
                ))}
          </SpaceBetween>
        </SpaceBetween>
      </ExpandableSection>
    </div>
  );
};

export default Taste;
